import { useState, useCallback, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';
import { Tooltip } from 'react-tooltip';

import { InputText, InputTextArea } from '../../../../common/UI/Inputs';
import { IOption } from '../../../../common/UI/Nabvar/DropdownSelect';
import Loading from '../../../../common/UI/Loading';
import InputDateFormik from '../../../../common/UI/Inputs/InputDateFormik';
import AppGetIcon from '../../../../common/UI/AppGetIcon';
import InputSearch from '../../../../common/UI/DataTable/inputSearch';
import DateInput from '../../../../common/UI/Inputs/InputDate';
import AppInputSearch from '../../../../common/UI/Inputs/appInputSearch';
import ProgressBar from '../../../../common/UI/ProgressBar';
import Modal from '../../../../common/UI/DataTable/modal/modal';

import useFormattedDate from '../../../../common/hooks/useFormattedDate';
import usePermission from '../../../../common/hooks/usePermission';

import { useInitDataStore } from '../../../../store/initData.store';

import { DataSelectDto } from '../../../../common/dtos/general.dto';

import style from './Reservations.module.css';
import styleButton from '../../../../common/theme/styles/button.module.css';
import styleForm from '../../../../common/theme/styles/form.module.css';
import styleDataTable from '../../../../common/UI/DataTable/dataTable.module.css';

import { GetReservationsWithPaginationService } from '../../services/Reservations/getReservationsWithPagination.service';
import { GetCampusWithPaginationService } from '../../services/getCampusWithPagination.service';
import { GetHoursBlockWithPaginationService } from '../../services/getHoursBlockWithPagination.service';
import { GetBuildingWithPaginationService } from '../../services/getBuildingWithPagination.service';
import { GetResourceWithPaginationService } from '../../services/Resources/getResourceWithPagination.service';
import { GetUsersWithPaginationService } from '../../services/getUsersWithPagination.service';
import { CreateOrUpdateReservationsService, IResource } from '../../services/Reservations/createOrUpdateReservations.service';
import { DeleteReservationsService } from '../../services/Reservations/deleteReservations.service';

const deleteReservationsService = new DeleteReservationsService();
const createOrUpdateReservationsService = new CreateOrUpdateReservationsService();
const getUsersWithPaginationService = new GetUsersWithPaginationService();

export default function Reservations() {
	const hFormatDate = useFormattedDate();

	const canEditStatus = usePermission('booking.reservationsStatus', 'edit');
	const canGetAll = usePermission('booking.reservations', 'list-all');

	const dataInitDataStore = useInitDataStore(
		(state) => ({
			userRoles: state.initData.userRoles.map(item => ({ value: String(item.roleId), label: item.role.name })),
			rolSelected: state.rolSelected,
			setRolSelected: state.setRolSelected,
			user: state.initData.user,
			userCampus: state.initData?.userCampus ?? [].map((item: IOption) => ({
				campusId: String(item.facultyCode),
				campusName: String(item.facultyName)
			})),
		}),
		shallow
	);
	console.log("dataInitDataStoredataInitDataStore", dataInitDataStore.user?.id);


	const today = new Date();
	const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
	const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

	const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
	const [editForm, setEditForm] = useState<boolean>(false);
	const [createForm, setCreateForm] = useState<boolean>(false);

	const [campus, setCampus] = useState<DataSelectDto>({
		label: '',
		value: ''
	});
	const [campusFilter, setCampusFilter] = useState<DataSelectDto>({
    label: dataInitDataStore?.userCampus?.[0]?.campusId ? String(dataInitDataStore.userCampus[0].campusId) : '',
    value: dataInitDataStore?.userCampus?.[0]?.campusId ? String(dataInitDataStore.userCampus[0].campusId) : ''
});

	console.log("dataInitDataStore.userCampus[0]?.campusId", dataInitDataStore.userCampus[0]?.campusId);

	const [buildingId, setBuildingId] = useState<DataSelectDto>({
		label: '',
		value: ''
	});
	const [resourceId, setResourceId] = useState<DataSelectDto>({
		label: '',
		value: ''
	});

	const [hoursBlockStart, setHoursBlockStart] = useState({ label: '', value: '' });
	const [hoursBlockEnd, setHoursBlockEnd] = useState({ label: '', value: '' });

	const [startDate, setStartDate] = useState<any>(startOfMonth);
	const [endDate, setEndate] = useState<any>(endOfMonth);
	const [hoursBlockCreated, setHoursBlockCreated] = useState<any[]>([]);
	const [dataCampus, setDataCampus] = useState<any[]>([]);
	const [selectedResources, setSelectedResources] = useState<any[]>([]);
	const [usersResponsibles, setUsersResponsibles] = useState({
		label: '',
		value: ''
	});
	const [usersResponsiblesMultipLe, setUsersResponsiblesMultipLe] = useState({
		label: '',
		value: ''
	});
	const [usersResponsiblesFilter, setUsersResponsiblesFilter] = useState({
		label: '',
		value: ''
	});
	const [usersRequestFilter, setUsersRequestFilter] = useState({
		label: '',
		value: ''
	});

	const [statusFilter, setStatusFilter] = useState<DataSelectDto>({
		label: dataInitDataStore.rolSelected === 55 ? 'Pendiente DGDE' : '',
		value: dataInitDataStore.rolSelected === 55 ? 'pending - DGDE' : ''
	});
	const [roomType, setRoomType] = useState<DataSelectDto>({
		label: '',
		value: ''
	});
	const [loadingForm, setLoadingForm] = useState<boolean>(false);
	const [messageBlock, setMessageBlock] = useState('');
	const [messageResource, setMessageResource] = useState('');

	const [isDisabledCampus, setDisabledCampus] = useState(false);
	const [isBlockHoveredPlus, setIsBlockHoveredPlus] = useState(false);
	const [isBlockHoveredDelete, setIsBlockHoveredDelete] = useState(false);
	const [isHoveredRequest, setIsHoveredRequest] = useState(false);
	const [isHoveredPlus, setIsHoveredPlus] = useState(false);
	const [isHoveredDelete, setIsHovereDelete] = useState(false);
	const [isHoveredDeleteReservations, setIsHovereDeleteReservations] = useState(false);
	const [isHoveredEditReserv, setIsHoveredEditReserv] = useState<boolean>(false);
	const [isHoveredCreate, setIsHoveredCreate] = useState<boolean>(false);
	const [isHoveredSave, setIsHoveredSave] = useState<boolean>(false);
	const [isHoveredCancel, setIsHoveredCancel] = useState<boolean>(false);

	const [reservationsData, setReservationsData] = useState<any[]>([]);
	const [isOpenCurrentDelete, setIsOpenCurrentDelete] = useState<any>(null);

	const [isOpenConfirm, setIsOpenConfirm] = useState<string | null>(null);


	const [isResponseModalOpen, setIsResponseModalOpen] = useState(false); // Estado para manejar el modal
	const [responseMessage, setResponseMessage] = useState<any>({}); // Estado para almacenar el mensaje de respuesta
	const [showInput, setShowInput] = useState(false);
	const [rejectionReason, setRejectionReason] = useState(''); // Para almacenar el valor del input
	const [showAdvancedFilters, setShowAdvancedFilters] = useState(false); //controlar los filtros avanzados

	const typeResourceData: DataSelectDto[] = [
		{
			label: 'Recintos Academicos',
			value: 'curricular'
		},
		{
			label: 'Extracurricular',
			value: 'extracurricular'
		}
	];
	const [typeResourceId, setTypeResourceId] = useState<DataSelectDto>({
		label: '',
		value: ''
	});

	const serviceCallCampus = useCallback(async (value: string) => {
		const response: any = await GetCampusWithPaginationService({
			search: value,
			perPage: 100,
			enabled: dataInitDataStore.rolSelected === 13 ? 1 : null
		});

		return response.data.map((item: any) => ({
			label: item.campusId,
			value: item.campusId
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps

	}, []);
	console.log("serviceCallCampusserviceCallCampus", dataCampus);

	const serviceCallBuilding = useCallback(
		async (dataSearch: any) => {
			if (campus.value) { // Si campus tiene un valor, realiza la llamada al servicio
				let campusSelected = campus.value || '';
				const response: any = await GetBuildingWithPaginationService({
					search: dataSearch,
					campusId: campusSelected,
					perPage: 100,
					sort: 'id',
					order: 'DESC'
				});
				return response.data.map((item: any) => ({
					label: item.buildingId,
					value: item.buildingId
				}));
			}
			return []; // Si no hay campusId, devuelve un array vacío o lo que necesites en su lugar
		// eslint-disable-next-line react-hooks/exhaustive-deps

		},
		[campus.value]
	);


	const serviceResource = useCallback(
		async (dataSearch: any) => {
			let campusSelected = campus.value || '';
			let buildingIdSelected = buildingId.value;

			// Verifica si campusId y buildingId tienen un valor antes de llamar al servicio
			if (campusSelected && buildingIdSelected) {
				const response: any = await GetResourceWithPaginationService({
					search: dataSearch,
					type: typeResourceId.value,
					campusId: campusSelected,
					buildingId: buildingIdSelected,
					perPage: 1000,
					sort: 'id',
					order: 'DESC',
					status: 1
				});

				return response.data.map((item: any) => ({
					...item,
					label: item.roomDescription,
					value: item.id
				}));
			}

			// Devuelve un array vacío si no hay campusId o buildingId
			return [];
		// eslint-disable-next-line react-hooks/exhaustive-deps

		},
		[campus.value, buildingId.value, typeResourceId.value]
	);

	const getRoomTypeOptions = () => {
		return [
			{ label: 'Recintos Academicos', value: 'internal' },
			{ label: 'Extracurricular', value: 'external' },
			{ label: 'Mixto', value: 'mixto' }
		];
	};
	const getStatusOptions = () => {
		return [
			{ label: 'Pendiente', value: 'pending' },
			{ label: 'Pendiente DGDE', value: 'pending - DGDE' },
			{ label: 'Por aprobar', value: 'for approved' },
			{ label: 'Aprobado', value: 'approved' },
			{ label: 'Rechazado', value: 'rejected' },
			{ label: 'Rechazado DGDE', value: 'rejected - DGDE' }
		];
	};
	const serviceUsersAll = async (value: any) => {
		let params: any = {
			search: value,
			uabEmails: 1
		};
		let response: any = await getUsersWithPaginationService.run(params);
		return response.data.map((item: any) => ({
			label: item.lastnames + ' ' + item.firstnames,
			value: item.id
		}));
	};

	const serviceCallHoursBlock = async (dataSearch: any) => {
		const response: any = await GetHoursBlockWithPaginationService(dataSearch);
		const filteredData = response.data
			.filter((item: any) => item.type === 0)
			.map((item: any) => ({
				...item,
				label: item.hourBlock,
				value: item.id
			}));
		return filteredData;
	};

	const serviceCallHoursBlockEnd = async (dataSearch: any) => {
		const response: any = await GetHoursBlockWithPaginationService(dataSearch);
		const filteredData = response.data
			.filter((item: any) => item.type === 1)
			.map((item: any) => ({
				...item,
				label: item.hourBlock,
				value: item.id
			}));
		return filteredData;
	};

	const createHoursBlocks = (block: any) => {
		// Validar si startDate y endDate están vacíos
		if (!block.startDate || !block.endDate || !block.startDate.value || !block.endDate.value) {
			setMessageBlock('Debe seleccionar un bloque horario de inicio y fin');
			return; // Salir de la función si no están definidos
		}

		const isDuplicate = hoursBlockCreated.some(
			(item: any) => item.startTime === block.startDate.label && item.endTime === block.endDate.label
		);

		if (!isDuplicate) {
			setHoursBlockCreated([
				...hoursBlockCreated,
				{
					startTimeId: block.startDate?.value,
					startTime: block.startDate?.label,
					endTimeId: block.endDate?.value,
					endTime: block.endDate?.label
				}
			]);
			setMessageBlock('');
			setHoursBlockStart({
				label: '',
				value: ''
			});
			setHoursBlockEnd({
				label: '',
				value: ''
			});
		} else {
			setMessageBlock('Ya existe un bloque con esa hora inicio - fin');
		}
	};


	const deleteHoursBlocks = (block: any) => {
		const updatedBlocks = hoursBlockCreated.filter(
			(item: any) => item.startTimeId !== block.startTimeId || item.endTimeId !== block.endTimeId
		);
		setHoursBlockCreated(updatedBlocks);
	};

	const addResource = async () => {
		const resourceExists = selectedResources.some(resource => resource.id === resourceId.value);
		if (initialValues.status === 'pending' && selectedResources?.some((room: any) =>
			!dataCampus.some(userCampus => String(userCampus.value) === String(room.campusId)))) {
				if (initialValues.requesterRoleId === 13) {
					setDisabledCampus(true)
				}else{
			setDisabledCampus(false)
				}
		} else {
			setDisabledCampus(false)
		}
		const response = await GetResourceWithPaginationService({
			search: '',
			type: typeResourceId.value,
			roomId: resourceId.value,
			status: 1
		});

		console.log('response', response);

		if (resourceExists) {
			setMessageResource('Este recurso ya está agregado.');
			return;
		};

		const newResourceCampusId = response.data[0].campusId;
		const newResourceBuildingId = response.data[0].buildingId;

		// Si no hay recursos seleccionados, permite agregar
		if (selectedResources.length === 0) {
			const newResource = {
				id: resourceId.value,
				name: resourceId.label,
				campusId: newResourceCampusId,
				buildingId: newResourceBuildingId,
				type: typeResourceId.value === 'extracurricular' ? 'Extracurricular' : 'Recintos Academicos'
			};
			handleGetCampus();

			setSelectedResources([...selectedResources, newResource]);
			setMessageResource('');
			setTypeResourceId({ label: '', value: '' });
			setResourceId({ label: '', value: '' });
			setBuildingId({ label: '', value: '' });
			setCampus({ label: '', value: '' });
		} else {
			// Validar que el campusId del nuevo recurso sea igual al de los ya seleccionados
			const firstResourceCampusId = selectedResources[0].campusId;

			if (newResourceCampusId === firstResourceCampusId) {
				const newResource = {
					id: resourceId.value,
					name: resourceId.label,
					campusId: newResourceCampusId,
					buildingId: newResourceBuildingId,
					type: typeResourceId.value === 'extracurricular' ? 'Extracurricular' : 'Recintos Academicos'
				};

				setSelectedResources([...selectedResources, newResource]);
				setMessageResource('');
				setResourceId({ label: '', value: '' });
				setBuildingId({ label: '', value: '' });
				setCampus({ label: '', value: '' });
			} else {
				setMessageResource('Solo puedes agregar recursos del mismo campus.');
			};
		};
	};

	const removeResource = (resourceId: string) => {
		setSelectedResources(selectedResources.filter(resource => resource.id !== resourceId));
	};
	const getStatusInfo = (status: string) => {
		switch (status) {
			case 'pending':
				return { color: '#FFA500', text: 'Pendiente' }; // Naranja
			case 'for approved':
				return { color: '#042F53', text: 'Por aprobar' }; // Dorado
			case 'pending - DGDE':
				return { color: '#042F53', text: 'Por aprobar DGDE' }; // Dorado
			case 'approved':
				return { color: '#15CB20', text: 'Aprobado' }; // Verde
			case 'rejected':
				return { color: '#962330', text: 'Rechazado' }; // Rojo
				case 'rejected - DGDE':
				return { color: '#962330', text: 'Rechazado por DGDE' }; // Rojo
			default:
				return { color: '', text: '' };
		}
	};

	const handleSubmitResource = async (values: any, actions: any) => {

		// Validar si hay responsables o recursos seleccionados
		if ((values.responsibles && values.responsibles.length > 0) && selectedResources.length > 0 || dataInitDataStore.rolSelected === 13) {
			// Realiza el guardado si las condiciones se cumplen o si el rol es 13
			await saveResource(values, 'pending');
			setEditForm(false);
			setCreateForm(false);
		} else {
			// Si no se cumplen las condiciones y el rol no es 13, muestra el toast
			toast.error(
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<AppGetIcon name="xCircleFill" />
					Debe seleccionar al menos un responsable y un recurso.
				</div>,
				{ icon: false }
			);
			setEditForm(true);
			setCreateForm(true);
		}

	};
	const handleUpdateResource = async (values: any, actions: any) => {
		// Validar si hay responsables o recursos seleccionados
			await saveResource(values, 'for approved');
			setEditForm(false);
			setCreateForm(false);

	};

	const [initialValues, setInitialValues] = useState({
		id: '',
		numberAttendees: '',
		eventName: '',
		startDate: '',
		endDate: '',
		status: '',
		requestedBy: '',
		requesterRoleId: null,
		approvedBy: '',
		approvalDate: '',
		rejectionComment: '',
		roomType: '',
		responsibles: [],
		rooms: [] as Array<{ id: number; type: string; description: string; campusId: string }>, // Definimos el tipo directamente en rooms
		intervals: [],
		approverDGDEName: '',
		approvedByDGDE: null

	});

	const handleApprovalRequest = async (values: any) => {
		console.log("holaaa344");

		// Validar si hay responsables o recursos seleccionados
		if ((!values.responsibles || values.responsibles.length === 0) && selectedResources.length === 0 && dataInitDataStore.rolSelected !== 13) {
			toast.error(
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<AppGetIcon name="xCircleFill" />
					Debe seleccionar al menos un responsable y un recurso.
				</div>,
				{ icon: false }
			);
			setEditForm(true);
			setCreateForm(true);
			return; // Detener la ejecución si falta algún campo necesario
		}

		try {
			// Intentar guardar el recurso
			await saveResource(values, 'for approved');

			// Si el guardado es exitoso, cambiar a modo de previsualización

		} catch (error) {
			// Si ocurre un error, mantener los inputs editables
			console.error('Error al solicitar aprobación:', error);
			setEditForm(true);
			setCreateForm(true);
		}
	};

	const handleApprovalRequestDGDE = async (values: any) => {
		// Validar si hay responsables o recursos seleccionados
		console.log("holaaa333");
		if ((!values.responsibles || values.responsibles.length === 0) && selectedResources.length === 0 && dataInitDataStore.rolSelected !== 13) {
			toast.error(
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<AppGetIcon name="xCircleFill" />
					Debe seleccionar al menos un responsable y un recurso.
				</div>,
				{ icon: false }
			);
			setEditForm(true);
			setCreateForm(true);
			return; // Detener la ejecución si falta algún campo necesario
		}

		try {
			// Intentar guardar el recurso
			await saveResource(values, 'pending - DGDE');

			// Si el guardado es exitoso, cambiar a modo de previsualización

		} catch (error) {
			// Si ocurre un error, mantener los inputs editables
			console.error('Error al solicitar aprobación:', error);
			setEditForm(true);
			setCreateForm(true);
		}
	};
	const handleApproved = async (values: any) => {
		console.log("funcion de aprobar", values);
		
		// Validar si hay responsables o recursos seleccionados
			await saveResource(values, 'approved');
			setEditForm(false);
			setCreateForm(false);
		
	};

	const handleRejected = async (values: any) => {
		// Validar si hay responsables o recursos seleccionados
		console.log("values rejecter", values);
		console.log("hola condicion responsible", values?.responsibles);
		console.log("hola condicion values?.rooms",values?.rooms);
		
		if ((values?.responsibles && values?.responsibles.length > 0) && values?.rooms && values?.rooms.length > 0 ) {
			console.log("hola condicion esperada");
			
			console.log(rejectionReason, "rejectionReasonrejectionReason");

			if (!rejectionReason || rejectionReason.trim() === "") {
				toast.error(
					<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
						<AppGetIcon name="xCircleFill" />
						Debe ingresar una descripción sobre esta solicitud.
					</div>,
					{ icon: false }
				);
				return;
			}
			await saveResource(values, 'rejected');

			setEditForm(false);
			setCreateForm(false);
		} else {
			toast.error(
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<AppGetIcon name="xCircleFill" />
					Debe seleccionar al menos un responsable y un recurso.
				</div>,
				{ icon: false }
			);
			setEditForm(true);
			setCreateForm(true);
		}
	};
	const handleRejectedDGDE = async (values: any) => {
		// Validar si hay responsables o recursos seleccionados
		console.log("values rejecter", values);
		
		if ((values?.responsibles && values?.responsibles.length > 0) && values?.rooms && values?.rooms.length > 0 ) {
			console.log("hola condicion esperada");
			
			console.log(rejectionReason, "rejectionReasonrejectionReason");

			if (!rejectionReason || rejectionReason.trim() === "") {
				toast.error(
					<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
						<AppGetIcon name="xCircleFill" />
						Debe ingresar una descripción sobre esta solicitud.
					</div>,
					{ icon: false }
				);
				return;
			}
			await saveResource(values, 'rejected - DGDE');

			setEditForm(false);
			setCreateForm(false);
		} else {
			toast.error(
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<AppGetIcon name="xCircleFill" />
					Debe seleccionar al menos un responsable y un recurso.
				</div>,
				{ icon: false }
			);
			setEditForm(true);
			setCreateForm(true);
		}
	};

	const handleSubmitBasicInfo = async (values: any) => {
		// Validar si hay responsables o recursos seleccionados
		await saveResource(values, 'pending');
		setEditForm(true);
		setCreateForm(true);
	};

	const determineRoomType = (rooms: any) => {
		const hasExternalRoomId = rooms.some((room: any) => room.externalRoomId);
		const hasInternalRoomId = rooms.some((room: any) => room.internalRoomId);

		if (hasExternalRoomId && hasInternalRoomId) {
			return 'mixto';
		} else if (hasExternalRoomId) {
			return 'external';
		} else if (hasInternalRoomId) {
			return 'internal';
		} else {
			return null;
		}
	};

	const handleGetReservations = useCallback(async () => {
		try {
			setLoadingSearch(true);
			const requestParams: any = {
				perPage: 1000,
				startDate: startDate ? hFormatDate(startDate, 'yyyy-MM-dd') : '',
				endDate: endDate ? hFormatDate(endDate, 'yyyy-MM-dd') : '',
				campusIds: dataInitDataStore.rolSelected === 13 || dataInitDataStore.rolSelected === 12 || dataInitDataStore.rolSelected === 56  ? null : campusFilter.value,
				responsibleIds: usersResponsiblesFilter.value,
				roomType: roomType.value,
				status: statusFilter.value,
				requestedBy: canGetAll === true ? usersRequestFilter.value : dataInitDataStore.user?.id,
				requesterRoleId: (dataInitDataStore.rolSelected === 55 || dataInitDataStore.rolSelected === 51 || dataInitDataStore.rolSelected === 1) ? null : dataInitDataStore.rolSelected,
				sort: 'b.id',
				order: 'DESC'
			};
			const response: any = await GetReservationsWithPaginationService(requestParams);
			await setReservationsData(
				response.data.map((item: any) => ({
					...item
				}))
			);

			setLoadingSearch(false);

		} catch (error) {
			console.log('Error al ejecutar el servicio de reservas', error);
			setLoadingSearch(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endDate, campusFilter, usersResponsiblesFilter, usersRequestFilter, roomType, statusFilter]);

	const handleGetCampus = useCallback(async () => {
		try {

			const responseCampus: any = await GetCampusWithPaginationService({
				perPage: 1000,
				enabled: 1
			});
			setDataCampus(responseCampus.data.map((item: any) => ({
				label: item.campusId,
				value: item.campusId
			})))
		} catch (error) {
			console.log('Error al ejecutar el servicio de campusdata', error);
			setLoadingSearch(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endDate, campusFilter, usersResponsiblesFilter, usersRequestFilter, roomType, statusFilter]);


	const saveResource = async (values: any, status: string) => {
		// Verificar si no hay responsables seleccionados
		console.log("aca ess", status);

		if ((!values.responsibles || values.responsibles.length === 0) && values.id && dataInitDataStore.rolSelected !== 13) {
			toast.error(
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<AppGetIcon name="xCircleFill" />
					Debe seleccionar al menos un responsable.
				</div>,
				{ icon: false }
			);
			return;
		}

		if (selectedResources.length === 0 && values.id) {
			toast.error(
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<AppGetIcon name="xCircleFill" />
					Debe seleccionar al menos un recurso.
				</div>,
				{ icon: false }
			);
			return;
		}

		setLoadingForm(true);
		
		// Preparar los datos del recurso a guardar
		const formattedRooms = selectedResources.map(resource => {
			return (resource.type === 'Extracurricular' || resource.type === 'Externo')
				? { externalRoomId: resource.id }
				: { internalRoomId: resource.id };
		});

		const roomType = determineRoomType(formattedRooms);

		const resourceData: IResource = {
			id: values.id || 0,
			numberAttendees: values.numberAttendees ? Number(values.numberAttendees) : null,
			eventName: values.eventName || null,

			startDate: values.startDate ? hFormatDate(values.startDate, 'yyyy-MM-dd') : '',
			endDate: values.endDate ? hFormatDate(values.endDate, 'yyyy-MM-dd') : '',
			status: status,
			requestedBy: values.requestedBy ? values.requestedBy : dataInitDataStore.user?.id || null,
			recurrence: null,
			approvedBy: status === 'approved' || status === 'rejected' ? dataInitDataStore.user?.id : null,
			approvedByDGDE: values.approvedByDGDE ? values.approvedByDGDE : dataInitDataStore.rolSelected === 55 ? dataInitDataStore.user?.id : null,
			approvalDate: status === 'approved' || status === 'rejected' ? new Date() : null,
			rejectionComment: rejectionReason ?? null,
			roomType: roomType || null,
			responsibles: dataInitDataStore.rolSelected === 13 ? ([{ userId: dataInitDataStore.user?.id }]) : values.responsibles ? values.responsibles.map((item: any) => ({ userId: item.value })) : [],
			rooms: formattedRooms,
			intervals: hoursBlockCreated.map((block: any) => ({
				startTimeId: block.startTimeId,
				endTimeId: block.endTimeId
			})),
			requesterRoleId: values.requesterRoleId ?? dataInitDataStore.rolSelected,
		};

		try {
			// Intentar guardar el recurso
			const response = await createOrUpdateReservationsService.run(resourceData);
			if (response.code) {
				// Actualizar el estado del modal y el mensaje de respuesta
				setResponseMessage(response);
				setIsResponseModalOpen(true);
			}
			if (response.code === 0 || response.code === 1) {
				toast.success(
					<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
						<AppGetIcon name="checkCircleFill" />
						{response.message}
					</div>,
					{ icon: false }
				);

				setInitialValues(prevValues => ({
					...prevValues,
					...values,
					id: response.id,
					status: status
				}));
				
				// Cambiar a modo de previsualización solo si la respuesta es exitosa
				// Limpia el campo después de confirmar
				setRejectionReason(''); // Limpia el estado del input
				setShowInput(false);    // Oculta el campo de input después de guardar
				setEditForm(false);
				setCreateForm(false);
			}

			// handleSelectedReservations(values)

			setUsersResponsiblesMultipLe(
				values.responsibles.map((responsible: any) => ({
					label: responsible.label,
					value: responsible.value
				}))
			);

			await handleGetReservations();
			await handleGetCampus();
			console.log("daaaajose222", initialValues);

			if (initialValues.status === 'pending' && selectedResources?.some((room: any) =>
				!dataCampus.some(userCampus => String(userCampus.value) === String(room.campusId)))) {
					if (initialValues.requesterRoleId === 13) {
						setDisabledCampus(true)
					}else{
				setDisabledCampus(false)
					}
			} else {
				setDisabledCampus(false)
			}
			setLoadingForm(false);

		} catch (error) {
			console.error('Error guardando la reserva', error);
			toast.error(
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<AppGetIcon name="xCircleFill" />
					Ocurrio un error, contactese con el administrador
				</div>,
				{ icon: false }
			);
			// Mantener los inputs editables si ocurre un error
			setEditForm(true);
			setCreateForm(true);

			setLoadingForm(false);
		}
	};

	const handleSelectedReservations = (reservation: any) => {
		const formattedResponsibles = reservation.responsibles.map((responsible: any) => ({
			label: responsible.fullName
				? responsible.fullName
				: `${responsible.firstnames} ${responsible.lastnames}`.trim(),
			value: responsible.id
		}));
		console.log("reservation", reservation);
		if (reservation.status === 'pending' && reservation?.rooms?.some((room: any) =>
			!dataCampus.some(userCampus => String(userCampus.value) === String(room.campusId)))) {
				if (reservation.requesterRoleId === 13) {
					setDisabledCampus(true)
				}else{
			setDisabledCampus(false)
				}
		} else {
			setDisabledCampus(false)
		}
		// Actualiza initialValues y usersResponsiblesMultipLe correctamente
		setInitialValues({
			id: reservation.id,
			numberAttendees: reservation.numberAttendees,
			eventName: reservation.eventName,
			startDate: hFormatDate(reservation.startDate, 'yyyy-MM-dd'),
			endDate: hFormatDate(reservation.endDate, 'yyyy-MM-dd'),
			status: reservation.status,
			requestedBy: reservation.requestedBy,
			requesterRoleId: reservation.requesterRoleId,
			approvedBy: reservation.approvedBy,
			approvalDate: reservation.approvalDate,
			rejectionComment: reservation.rejectionComment,
			roomType: reservation.roomType,
			responsibles: formattedResponsibles,
			rooms: reservation.rooms || [],
			intervals: reservation.intervals || [],
			approverDGDEName: reservation.approverDGDEName,
			approvedByDGDE: reservation.approvedByDGDE
			
		});

		setUsersResponsiblesMultipLe(formattedResponsibles);

		setSelectedResources(
			reservation.rooms.map((room: any) => ({
				id: room.id,
				name: room.description,
				campusId: room.campusId,
				buildingId: room.buildingId,
				type: room.type
			}))
		);

		setHoursBlockCreated(
			reservation.intervals.map((interval: any) => ({
				startTimeId: interval.startTimeId,
				startTime: interval.startHourBlock,
				endTimeId: interval.endTimeId,
				endTime: interval.endHourBlock
			}))
		);

		// Asegúrate de que el formulario esté en modo de visualización cuando no se está editando
		setEditForm(false);
		setCreateForm(false);
	};

	const handleUnSelectedReservation = () => {
		const formattedResponsibles: any = [];

		// Actualiza initialValues y usersResponsiblesMultipLe correctamente
		setInitialValues({
			id: '',
			numberAttendees: '',
			eventName: '',
			startDate: '',
			endDate: '',
			status: '',
			requestedBy: '',
			approvedBy: '',
			requesterRoleId: null,
			approvalDate: '',
			rejectionComment: '',
			roomType: '',
			responsibles: [],
			rooms: [],
			intervals: [],
			approverDGDEName: '',
			approvedByDGDE: null
		});

		setUsersResponsiblesMultipLe(formattedResponsibles);

		setSelectedResources([]);

		setHoursBlockCreated([]);

		// Asegúrate de que el formulario esté en modo de visualización cuando no se está editando
		setEditForm(false);
		setCreateForm(false);
	};

	const handleDelete = async () => {
		let params: any = {
			bookingId: isOpenCurrentDelete?.id
		};

		setIsOpenCurrentDelete(null); // Cierra el modal
		setLoadingForm(true);
		try {
			await deleteReservationsService.run(params); // Llama al servicio de eliminación con el ID del elemento seleccionado

			// Limpiar la vista de "Mis reservas"
			setInitialValues({
				id: '',
				numberAttendees: '',
				eventName: '',
				startDate: '',
				endDate: '',
				status: '',
				requestedBy: '',
				requesterRoleId: null,
				approvedBy: '',
				approvalDate: '',
				rejectionComment: '',
				roomType: '',
				responsibles: [],
				rooms: [],
				intervals: [],
				approverDGDEName: '',
				approvedByDGDE: null
			});
			setSelectedResources([]); // Limpiar recursos seleccionados
			setHoursBlockCreated([]); // Limpiar bloques de horas creados

			// Llama al método para obtener las reservas actualizadas
			await handleGetReservations();
			await handleGetCampus();

			setLoadingForm(false);
		} catch (error) {
			console.error('Error al eliminar la reserva:', error);
			setLoadingForm(false);
		}
	};
	const handleCloseModalDelete = () => {
		setIsOpenCurrentDelete(false);
	};

	const handleConfirm = () => {
		if (showInput && !rejectionReason) {
			toast.error(
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<AppGetIcon name="xCircleFill" />
					Debes ingresar una razón antes de confirmar.
				</div>,
				{ icon: false }
			);
			return;
		}
		console.log("holaaa111", isOpenConfirm);
		console.log("initialValuesinitialValues", initialValues);

		// Continúa con la acción de confirmación si hay una razón válida
		if (isOpenConfirm === 'for approved') {
			handleApprovalRequest(initialValues);
		}
		if (isOpenConfirm === 'pending - DGDE') {
			console.log("holaaa2222");

			handleApprovalRequestDGDE(initialValues);
		}
		if (isOpenConfirm === 'approved') {
			console.log("hola approved");
			handleApproved(initialValues);
		}

		if (isOpenConfirm === 'rejected') {
			console.log("hola rejecter");
			handleRejected(initialValues)
		}
		if (isOpenConfirm === 'rejected - DGDE') {
			console.log("hola rejecter");
			handleRejectedDGDE(initialValues)
		}
		setIsOpenConfirm(''); // Cierra el modal
	};
	const handleCloseModalConfirm = () => {
		setIsOpenConfirm('');
	};

	useEffect(() => {
		handleGetReservations();
		handleGetCampus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleGetReservations, handleGetCampus]);

	return (
		<div className={style.Resources__page}>
			<div className={style.Page__card_filters}>
				<div className={style.Card__filters_head}>
					<DateInput
						name="fecha"
						textLabel="Selecionar rango"
						classNameInput={styleForm.input__input}
						initialValue={[startDate, endDate]}
						range={true}
						dateFormat="dd-MM-yyyy"
						onChange={(dateSelected: any) => {
							setStartDate(dateSelected[0]);
							setEndate(dateSelected[1]);
							setEditForm(false);
							setCreateForm(false);
							handleUnSelectedReservation();
						}}
					/>
					{canGetAll && (
						<InputSearch
							key={Math.random()}
							name="rooms"
							classNameInput={styleForm.input__input}
							textLabel="Selecciona tipo de reserva"
							data={getRoomTypeOptions()}
							onSelect={selected => {
								setRoomType(selected);
								handleUnSelectedReservation();
							}}
							selected={roomType}
						/>
					)}
					<InputSearch
						key={Math.random()}
						name="status"
						classNameInput={styleForm.input__input}
						textLabel="Selecciona estado"
						data={getStatusOptions()}
						onSelect={selected => {
							setStatusFilter(selected);
							handleUnSelectedReservation();
						}}
						selected={statusFilter}
					/>
					{/* Botón para mostrar/ocultar filtros avanzados con borde e ícono */}
					{canGetAll && (
						<>
							<button
								onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
								className={`${style.Filters__head_advanceFilters} ${showAdvancedFilters ? 'active' : ''}`}
							>
								{showAdvancedFilters ? 'Ocultar filtros avanzados' : 'Mostrar filtros avanzados'}
								<AppGetIcon
									name="arrow-down"
									strokeColor='#042F53'
									className={`${style.Icon_size} ${showAdvancedFilters ? style.Icon_down : style.Icon_rotate}`}
								/>
							</button>
							{/* Filtros avanzados condicionalmente renderizados */}
							{showAdvancedFilters && (
								<>
									<InputSearch
										key={Math.random()}
										classNameInput={styleForm.input__input}
										textLabel="Selecciona campus"
										serviceCall={serviceCallCampus}
										onSelect={selected => {
											console.log('selected', selected);
											setCampusFilter(selected);
											setBuildingId({ label: '', value: '' });
											setStatusFilter({ label: '', value: '' });
											handleUnSelectedReservation();
										}}
										selected={campusFilter}
									/>
									<InputSearch
										classNameInput={styleForm.input__input}
										serviceCall={serviceUsersAll}
										onSelect={selected => {
											setUsersResponsiblesFilter({
												label: selected.label,
												value: selected.value
											});
											setStatusFilter({ label: '', value: '' });
											handleUnSelectedReservation();

										}}
										selected={usersResponsibles}
										name="responsibles"
										textLabel="Selecciona responsable"
										multiple={true}
									/>
									<InputSearch
										classNameInput={styleForm.input__input}
										serviceCall={serviceUsersAll}
										onSelect={selected => {
											setUsersRequestFilter({
												label: selected.label,
												value: selected.value
											});
											setStatusFilter({ label: '', value: '' });
											handleUnSelectedReservation();
										}}
										selected={usersRequestFilter}
										name="requestedBy"
										textLabel="Selecciona solicitante"
										multiple={true}
									/>
								</>
							)}
						</>
					)}
				</div>
				<div className={style.Card__filters_body}>
					{loadingSearch && <Loading />}
					{reservationsData.length > 0 ? (
						reservationsData.map((item: any, index: any) => (
							<div key={index} className={`${style.Card__resources} ${initialValues?.id === item.id ? style.Card__resources_selected : ''}`} onClick={() => handleSelectedReservations(item)}>
								<div className={style.Card__resources_container}>
									<div className={`${style.Card__resources_title} ${style.Card__title_start}`}> Nombre evento </div>
									<div>{item.eventName}</div>
								</div>
								<div className={style.Card__resources_container}>
									<div className={style.Card__resources_title}>Responsables</div>
									<div className={style.Card__resources_value}>
										{item.responsibles && item.responsibles.length > 0 ? (
											item.responsibles.map((responsible: any, index: any) => (
												<span key={index}>
													{responsible.fullName}
												</span>
											))
										) : (<div>No hay responsables asignados</div>)
										}
									</div>
								</div>

								<div className={style.Card__resources_container}>
									<div className={style.Card__resources_title}>Nombre Salón</div>
									<div className={style.Card__resources_value}>
										{item.rooms && item.rooms.length > 0 ? (
											item.rooms.map((room: any) => room.description).join(', ')
										) : (<div>No hay salones asignados</div>)
										}
									</div>
								</div>

								<div className={style.Card__resources_container}>
									<div className={style.Card__resources_title}>Bloques horarios</div>
									<div className={style.Card__resources_value}>
										{item.intervals && item.intervals.length > 0 ? (
											item.intervals
												.map(
													(interval: any) =>
														`${interval.startHourBlock} - ${interval.endHourBlock}`
												)
												.join(', ')
										) : (<div>No hay bloques horarios asignados</div>)
										}
									</div>
								</div>

								<div className={style.Card__resources_container}>
									<div className={style.Card__resources_title}>Estado</div>
									<div style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'center' }} className={style.Card__resources_value}>
										<div className={style.Card__resources_status} style={{ background: getStatusInfo(item.status).color }}>
											{getStatusInfo(item.status).text}
										</div>
									</div>
								</div>
							</div>
						))
					) : (
						<div style={{ flexDirection: 'column' }} className={styleDataTable.Container__notFound}>
							<AppGetIcon name="infoCircle" />
							<span style={{ width: '100%' }}> No se encontraron reservas que coincidan con tu búsqueda. </span>
						</div>
					)}
				</div>
			</div>
			<div className={style.Page__card_view}>
				<div className={style.Card__view_header}>
					<div>
						{(initialValues && initialValues.id) ? (
							<div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>Reserva - {initialValues.eventName ?? ''}{' '} -
								<div className={style.Card__resources_status} style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'center', fontSize: '15px', background: getStatusInfo(initialValues.status).color }}>
									{getStatusInfo(initialValues.status).text}
								</div>
							</div>
						) : createForm ? ('Crear reserva') : ('')
						}
					</div>
					<div>
						<div style={{ display: 'flex', gap: '5px', justifyContent: 'flex-end' }}>
							{initialValues && dataInitDataStore.rolSelected !== 55 && !editForm && !createForm && initialValues.id &&
								(
									(
										// Condiciones para cuando el estado es 'pending'
										(initialValues.status === 'pending' && (
											(initialValues.requesterRoleId === 13 && dataInitDataStore.rolSelected === 13) ||
											(initialValues.requesterRoleId !== 13 &&
												(dataInitDataStore.rolSelected === 51 ||
													dataInitDataStore.rolSelected === 1 ||
													dataInitDataStore.rolSelected === initialValues.requesterRoleId)
											)
										)) ||
										// Condiciones para cuando el estado es 'for approved'
										(initialValues.status === 'for approved' &&
											(dataInitDataStore.rolSelected === 51 || dataInitDataStore.rolSelected === 1))
									) && 
									initialValues?.rooms?.every((room) => {
										return dataInitDataStore.userCampus.some((userCampus) =>
											String(userCampus.campusId) === String(room.campusId)
										);
									})
								) ? (
								<div
									className={`${styleButton.Buttom_option} ${styleButton.TextWithout} ${style.Button_header}`}
									onClick={() => { setIsOpenCurrentDelete(initialValues); setIsHovereDeleteReservations(false) }}
									onMouseEnter={() => setIsHovereDeleteReservations(true)}
									onMouseLeave={() => setIsHovereDeleteReservations(false)}>
									<AppGetIcon
										name="trash"
										classIcon={styleDataTable.sizeIcon}
										strokeColor="#962330"
										hoverColor={isHoveredDeleteReservations ? '#FFF' : undefined}
										data-tooltip-id="my-tooltip-delete"
										data-tooltip-content={("Eliminar")}
									/>
									<Tooltip
										id="my-tooltip-delete"
										place="bottom"
										style={{
											wordBreak: 'keep-all',
											overflowWrap: 'break-word', /* Permite que las palabras se rompan y pasen a la siguiente línea si es necesario */
											hyphens: 'manual', /* Evita los guiones automáticos */
											backgroundColor: "#41545C",
											color: "#FFFFFF",
											maxWidth: '300px',
											zIndex: 10000,
											fontWeight: 'bold',
											textTransform: 'unset'
										}}
									/>
								</div>
							) : (
								!canEditStatus &&
								initialValues?.status === 'pending' && dataInitDataStore.rolSelected !== 55 && (
									<div
										className={`${styleButton.Buttom_option} ${styleButton.TextWithout} ${style.Button_header}`}
										onClick={() => setIsOpenCurrentDelete(initialValues)}
										onMouseEnter={() => setIsHovereDeleteReservations(true)}
										onMouseLeave={() => setIsHovereDeleteReservations(false)}>
										<AppGetIcon
											name="trash"
											classIcon={styleDataTable.sizeIcon}
											strokeColor="#962330"
											hoverColor={isHoveredDeleteReservations ? '#FFF' : undefined}
											data-tooltip-id="my-tooltip-delete"
											data-tooltip-content={("Eliminar")}
										/>
										<Tooltip
											id="my-tooltip-delete"
											place="bottom"
											style={{
												wordBreak: 'keep-all',
												overflowWrap: 'break-word', /* Permite que las palabras se rompan y pasen a la siguiente línea si es necesario */
												hyphens: 'manual', /* Evita los guiones automáticos */
												backgroundColor: "#41545C",
												color: "#FFFFFF",
												maxWidth: '300px',
												zIndex: 10000,
												fontWeight: 'bold',
												textTransform: 'unset'
											}}
										/>
									</div>
								)
							)}

							{initialValues && dataInitDataStore.rolSelected !== 55 && !editForm && !createForm && initialValues.id &&
								(
									(
										// Condiciones para cuando el estado es 'pending'
										(initialValues.status === 'pending' && (
											(initialValues.requesterRoleId === 13 && dataInitDataStore.rolSelected === 13) ||
											(initialValues.requesterRoleId !== 13 &&
												(dataInitDataStore.rolSelected === 51 ||
													dataInitDataStore.rolSelected === 1 ||
													dataInitDataStore.rolSelected === initialValues.requesterRoleId)
											)
										)) ||
										// Condiciones para cuando el estado es 'for approved'
										(initialValues.status === 'for approved' &&
											(dataInitDataStore.rolSelected === 51 || dataInitDataStore.rolSelected === 1))
									) &&
									initialValues?.rooms?.every((room) => {
										return dataInitDataStore.userCampus.some((userCampus) =>
											String(userCampus.campusId) === String(room.campusId)
										);
									})
								) ? (
								<div
									className={`${styleButton.Buttom_option} ${styleButton.TextWithout} ${style.Button_header}`}
									onClick={() => {
										setIsHoveredEditReserv(false);
										setEditForm(true);
										setCreateForm(false);
									}}
									onMouseEnter={() => setIsHoveredEditReserv(true)}
									onMouseLeave={() => setIsHoveredEditReserv(false)}
								>
									<AppGetIcon
										name="edit"
										classIcon={styleDataTable.sizeIcon}
										strokeColor="#962330"
										hoverColor={isHoveredEditReserv ? '#FFF' : undefined}
										data-tooltip-id="my-tooltip-edit"
										data-tooltip-content="Editar"
									/>
									<Tooltip
										id="my-tooltip-edit"
										place="bottom"
										style={{
											wordBreak: 'keep-all',
											overflowWrap: 'break-word',
											hyphens: 'manual',
											backgroundColor: "#41545C",
											color: "#FFFFFF",
											maxWidth: '300px',
											zIndex: 10000,
											fontWeight: 'bold',
											textTransform: 'unset'
										}}
									/>
								</div>
							) : (!canEditStatus && !editForm && 
								initialValues?.status === 'pending' && dataInitDataStore.rolSelected !== 55 && (
									<div
										className={`${styleButton.Buttom_option} ${styleButton.TextWithout} ${style.Button_header}`}
										onClick={() => {
											setIsHoveredEditReserv(false);
											setEditForm(true);
											setCreateForm(false);
										}}
										onMouseEnter={() => setIsHoveredEditReserv(true)}
										onMouseLeave={() => setIsHoveredEditReserv(false)}
									>
										<AppGetIcon
											name="edit"
											classIcon={styleDataTable.sizeIcon}
											strokeColor="#962330"
											hoverColor={isHoveredEditReserv ? '#FFF' : undefined}
											data-tooltip-id="my-tooltip-edit"
											data-tooltip-content="Editar"
										/>
										<Tooltip
											id="my-tooltip-edit"
											place="bottom"
											style={{
												wordBreak: 'keep-all',
												overflowWrap: 'break-word',
												hyphens: 'manual',
												backgroundColor: "#41545C",
												color: "#FFFFFF",
												maxWidth: '300px',
												zIndex: 10000,
												fontWeight: 'bold',
												textTransform: 'unset'
											}}
										/>
										</div>
										)
							)}



										{!editForm && !createForm && dataInitDataStore.rolSelected !== 55 && (
											<div
												className={`${styleButton.Buttom_option} ${styleButton.TextWithout} ${style.Button_header}`}
												onClick={() => {
													setIsHoveredCreate(false); // Restablece el estado antes de eliminar o modificar el componente
													setCreateForm(true);  // Activa el modo de creación
													setEditForm(false);    // Desactiva el modo de edición
													setInitialValues({
														id: '',
														numberAttendees: '',
														eventName: '',
														startDate: '',
														endDate: '',
														status: '',
														requestedBy: '',
														requesterRoleId: null,
														approvedBy: '',
														approvalDate: '',
														rejectionComment: '',
														roomType: '',
														responsibles: [],
														rooms: [],
														intervals: [],
														approverDGDEName: '',
														approvedByDGDE: null
													});
													setHoursBlockCreated([]);  // Limpia los bloques de horas creados
													setSelectedResources([]);  // Limpia los recursos seleccionados
												}}
												onMouseEnter={() => setIsHoveredCreate(true)}
												onMouseLeave={() => setIsHoveredCreate(false)}
											>
												<AppGetIcon
													name="plus"
													classIcon={styleDataTable.sizeIcon}
													hoverColor={isHoveredCreate ? '#FFF' : undefined}
													data-tooltip-id="my-tooltip-create"
													data-tooltip-content={("Crear")}
												/>

												<Tooltip
													id="my-tooltip-create"
													place="bottom"
													style={{
														wordBreak: 'keep-all',
														overflowWrap: 'break-word', /* Permite que las palabras se rompan y pasen a la siguiente línea si es necesario */
														hyphens: 'manual', /* Evita los guiones automáticos */
														backgroundColor: "#41545C",
														color: "#FFFFFF",
														maxWidth: '300px',
														zIndex: 10000,
														fontWeight: 'bold',
														textTransform: 'unset'
													}}
												/>
											</div>
										)}
									</div>
					</div>
					</div>


					{loadingForm && <ProgressBar />}
					<div className={style.Card__view_body}>
						{(reservationsData.length === 0 && !createForm) ? (
							// Caso: No hay eventos y no se está creando una nueva reserva
							<div className={style.Container__info}>
								<div className={style.Container__infoMessage}>
									<AppGetIcon name="infoCircle" classIcon={style.iconInfo} /> No se encontraron reservas que coincidan con tu búsqueda.
								</div>
							</div>
						) : (!initialValues.id && !createForm) ? (
							// Caso: Hay eventos, pero no se ha seleccionado ningún evento y no se está creando uno nuevo
							<div className={style.Container__info}>
								<div className={style.Container__infoMessage}>
									<AppGetIcon name="infoCircle" classIcon={style.iconInfo} /> Debes seleccionar una reserva o crear una nueva.
								</div>
							</div>
						) : (
							// Caso: Se está creando o editando una reserva (Formulario)
							<Formik initialValues={initialValues} enableReinitialize={true} onSubmit={handleSubmitResource}>
								{({ values, setFieldValue }) => (
									<Form id="reservationForm" className={styleForm.flex__row} style={{ width: '100%' }}>
										<h4 className={style.under__line}>Datos Generales</h4>
										<div className={style.form__container_row}>
											{editForm || createForm ? (
												<InputText
													type="text"
													name="eventName"
													classNameInput={styleForm.input__input}
													placeholder="Digite el nombre del evento"
													TextLabel="Nombre evento"
													disabled={!createForm && (
														((initialValues?.status === 'for approved' || initialValues?.status === 'approved' || initialValues?.status === 'rejected') && !canEditStatus) || String(initialValues?.requestedBy) !== String(dataInitDataStore.user?.id))} // Convertimos ambos a string
												/>
											) : (
												<div className={style.row__item}>
													<label htmlFor="eventName">Nombre evento</label>
													<div>{values.eventName} </div>
												</div>
											)}
											{editForm || createForm ? (
												<InputText
													type="number"
													classNameInput={styleForm.input__input}
													name="numberAttendees"
													placeholder="Digite el número de asistentes"
													TextLabel="N° asistentes"
													disabled={
														!createForm &&
														(
															((initialValues?.status === 'for approved' ||
																initialValues?.status === 'approved' ||
																initialValues?.status === 'rejected') && !canEditStatus) ||
															String(initialValues?.requestedBy) !== String(dataInitDataStore.user?.id)
														)
													} // La condición no aplica si está en modo creación
												/>
											) : (
												<div className={style.row__item}>
													<label htmlFor="numberAttendees">Número asistentes</label>
													<div>{values.numberAttendees}</div>
												</div>
											)}
										</div>

										<div className={style.form__container_row}>
											{editForm || createForm ? (
												<InputDateFormik
													TextLabel="Fecha inicio*"
													name="startDate"
													minDate={new Date(new Date(values.startDate).setHours(0, 0, 0, 0))}

													disabled={!createForm && (((initialValues?.status === 'for approved' || initialValues?.status === 'approved' || initialValues?.status === 'rejected') && !canEditStatus) || String(initialValues?.requestedBy) !== String(dataInitDataStore.user?.id))} // Convertimos ambos a string
												/>
											) : (
												<div className={style.row__item}>
													<label htmlFor="startDate">Fecha inicio</label>
													<div>{values.startDate ? hFormatDate(values.startDate, 'yyyy-MM-dd') : ''} </div>
												</div>
											)}
											{editForm || createForm ? (
												<InputDateFormik
													TextLabel="Fecha fin*"
													name="endDate"
													minDate={new Date(values.startDate)}  // Calculamos 48 horas más a la fecha de inicio

													disabled={!createForm && (((initialValues?.status === 'for approved' || initialValues?.status === 'approved' || initialValues?.status === 'rejected') && !canEditStatus) || String(initialValues?.requestedBy) !== String(dataInitDataStore.user?.id))} // Convertimos ambos a string
												/>
											) : (
												<div className={style.row__item}>
													<label htmlFor="endDate">Fecha fin</label>
													<div>{values.endDate ? hFormatDate(values.endDate, 'yyyy-MM-dd') : ''} </div>
												</div>
											)}
										</div>
										{(editForm || createForm) && (
											<>
												<div className={style.form__container_row}>
													<InputSearch
														key={Math.random()}
														serviceCall={serviceCallHoursBlock}
														onSelect={selected =>
															setHoursBlockStart({
																label: selected.label,
																value: selected.value
															})
														}
														selected={hoursBlockStart}
														name="startHoursBlock"
														textLabel="Bloque inicio*"
														classNameInput={styleForm.input__input}
														disabled={!createForm && (((initialValues?.status === 'for approved' || initialValues?.status === 'approved' || initialValues?.status === 'rejected') && !canEditStatus) || String(initialValues?.requestedBy) !== String(dataInitDataStore.user?.id))} // Convertimos ambos a string
													/>
													<InputSearch
														key={Math.random()}
														serviceCall={serviceCallHoursBlockEnd}
														onSelect={setHoursBlockEnd}
														selected={hoursBlockEnd}
														name="endHoursBlock"
														textLabel="Bloque fin*"
														classNameInput={styleForm.input__input}
														disabled={!createForm && (((initialValues?.status === 'for approved' || initialValues?.status === 'approved' || initialValues?.status === 'rejected') && !canEditStatus) || String(initialValues?.requestedBy) !== String(dataInitDataStore.user?.id))} // Convertimos ambos a string
													/>
													{(initialValues?.status !== 'approved' && initialValues?.status !== 'rejected') ? (
														<div className={style.buttomSave}>
															<label></label>
															<button
																style={{ minHeight: '38px' }}
																type="button"
																className={styleButton.buttomAdd}
																disabled={!createForm && (((initialValues?.status === 'for approved' || initialValues?.status === 'approved' || initialValues?.status === 'rejected') && !canEditStatus) || String(initialValues?.requestedBy) !== String(dataInitDataStore.user?.id))} // Convertimos ambos a string
																onMouseEnter={() => setIsBlockHoveredPlus(true)}
																onMouseLeave={() => setIsBlockHoveredPlus(false)}
																onClick={() =>
																	createHoursBlocks({
																		startDate: hoursBlockStart,
																		endDate: hoursBlockEnd
																	})
																}>
																Agregar
																<AppGetIcon
																	name="plus"
																	strokeColor="#042F53"
																	hoverColor={isBlockHoveredPlus ? '#FFF' : undefined}
																/>
															</button>
														</div>
													) : (
														''
													)}
												</div>
											</>
										)}
										<div className={style.form__container_row}>
											<div style={{ width: '100%', justifyContent: 'flex-start' }}>
												<h4 className={style.under__line}>Lista de bloques creados</h4>
												{messageBlock && (<div
													style={{
														width: '100%',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}>
													<AppGetIcon name="infoCircle" />{messageBlock}
												</div>)}
												<div className={style.container_blocks}>
													{hoursBlockCreated.length > 0 ? (
														hoursBlockCreated.map((block: any, index: number) => (
															<div key={`${block.startTimeId}-${block.endTimeId}-${index}`} className={style.container_blocks_content}>
																{block.startTime} - {block.endTime}
																{(editForm || createForm) && (
																	(createForm || (
																		((!canEditStatus && (initialValues?.status === 'pending' || initialValues?.status === '')) ||
																			(canEditStatus && (initialValues?.status === 'for approved' || initialValues?.status === 'pending'))) &&
																		String(initialValues?.requestedBy) === String(dataInitDataStore.user?.id)
																	)) && (
																		<AppGetIcon
																			onMouseEnter={() => setIsBlockHoveredDelete(true)}
																			onMouseLeave={() => setIsBlockHoveredDelete(false)}
																			style={{
																				width: '25px',
																				height: '25px'
																			}}
																			onClick={() => deleteHoursBlocks(block)}
																			name="trash"
																			strokeColor="#5B5F62"
																			hoverColor={isBlockHoveredDelete ? '#962330' : undefined}
																		/>
																	)
																)}
															</div>
														))
													) : (
														<div
															style={{
																width: '100%',
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
															}}>
															<AppGetIcon name="infoCircle" /> No tienes bloques horarios
															agregados
														</div>
													)}
												</div>
											</div>
										</div>

										{values.id && (
											<>
												{dataInitDataStore.rolSelected !== 13 && (
													<>
														<h4 className={style.under__line}>Responsables</h4>
														<div style={{ width: '100%', minWidth: '260px' }}>
															{editForm || createForm ? (
																<AppInputSearch
																	classNameInput={styleForm.input__input}
																	serviceCall={serviceUsersAll}
																	onSelect={selected => {
																		setFieldValue('responsibles', selected.value);
																		setUsersResponsibles({
																			label: selected.label,
																			value: selected.value
																		});
																	}}
																	selected={usersResponsiblesMultipLe}
																	name="responsibles"
																	multiple={true}
																	disabled={!createForm && (((initialValues?.status === 'for approved' || initialValues?.status === 'approved' || initialValues?.status === 'rejected') && !canEditStatus) || String(initialValues?.requestedBy) !== String(dataInitDataStore.user?.id))} // Convertimos ambos a string
																/>
															) : (

																<div className={style.container_blocks}>

																	{values.responsibles && values.responsibles.length > 0 ? (
																		values.responsibles.map((item: any, index: number) => (
																			<span
																				key={index} // Asegúrate de usar una key única como `id` o cualquier otra propiedad única
																				style={{
																					display: 'flex',
																					alignItems: 'center',
																					backgroundColor: '#e0e0e0',
																					borderRadius: '16px',
																					padding: '0.25rem 0.5rem',
																					margin: '0.25rem',
																					fontSize: '0.875rem'
																				}}
																			>
																				{item.label} {/* Asegúrate de que `label` es la propiedad correcta */}
																			</span>
																		))
																	) : (
																		<div>No hay responsables asignados</div>
																	)}
																</div>

															)}
														</div>
													</>
												)}
												{(editForm || createForm) && (
													<>
														<h4 className={style.under__line}>Recursos</h4>

														<div className={style.form__container_row}>
															<InputSearch
																key={Math.random()} // Esto asegura que el componente InputSearch se renderiza de nuevo cada vez que cambie.
																name="type"
																classNameInput={styleForm.input__input}
																textLabel="Tipo recurso"
																icon=''
																data={typeResourceData} // Los datos que se muestran en el InputSearch.
																onSelect={selected => { // Función que se ejecuta cuando se selecciona un recurso.
																	setTypeResourceId(selected); // Actualiza el tipo de recurso seleccionado.
																	setCampus({ label: '', value: '' }); // Resetea el valor de "campus".
																	setBuildingId({ label: '', value: '' }); // Resetea el valor de "edificio".
																	setResourceId({ label: '', value: '' });
																}}
																selected={typeResourceId} // Valor seleccionado actualmente.
																disabled={(initialValues?.status === 'for approved' || initialValues?.status === 'approved' || initialValues?.status === 'rejected') && !canEditStatus}
															/>

															{dataInitDataStore.rolSelected === 13 ? (
																<>
																	<InputSearch
																		key={Math.random()}
																		name="sede"
																		classNameInput={styleForm.input__input}
																		textLabel="Selecciona sede/campus"
																		serviceCall={serviceCallCampus}
																		onSelect={selected => {
																			setCampus(selected);
																			setBuildingId({ label: '', value: '' });
																			setResourceId({ label: '', value: '' });
																		}}
																		icon="infoCircle"
																		tooltipText="Solo se muestran los Campus habilitados"
																		selected={campus}
																		disabled={
																			(initialValues?.status === 'for approved' ||
																				initialValues?.status === 'approved' ||
																				initialValues?.status === 'rejected') &&
																			!canEditStatus
																		}
																	/>
																</>

															)
																: (
																	<InputSearch
																		key={Math.random()}
																		name="sede"
																		classNameInput={styleForm.input__input}
																		textLabel="Selecciona sede/campus"
																		serviceCall={serviceCallCampus}
																		onSelect={selected => {
																			setCampus(selected);
																			setBuildingId({ label: '', value: '' });
																			setResourceId({ label: '', value: '' });
																		}}
																		selected={campus}
																		disabled={(initialValues?.status === 'for approved' || initialValues?.status === 'approved' || initialValues?.status === 'rejected') && !canEditStatus}
																	/>
																)}



														</div>

														<div className={style.form__container_row}>
															<InputSearch
																key={Math.random()}
																name="building"
																classNameInput={styleForm.input__input}
																textLabel="Selecciona edificio"
																serviceCall={serviceCallBuilding}
																onSelect={selected => {
																	setBuildingId(selected);
																	setResourceId({ label: '', value: '' });

																}}
																selected={buildingId}
																disabled={(initialValues?.status === 'for approved' || initialValues?.status === 'approved' || initialValues?.status === 'rejected') && !canEditStatus}
															/>
															<InputSearch
																key={Math.random()}
																name="building"
																classNameInput={styleForm.input__input}
																textLabel="Selecciona Recurso"
																serviceCall={serviceResource}
																onSelect={selected => {
																	setResourceId(selected);
																}}
																selected={resourceId}
																disabled={(initialValues?.status === 'for approved' || initialValues?.status === 'approved' || initialValues?.status === 'rejected') && !canEditStatus}
															/>
															<div className={style.buttomSave}>
																<button
																	style={{ minHeight: '38px' }}
																	type="button"
																	className={styleButton.buttomAdd}
																	onMouseEnter={() => setIsHoveredPlus(true)}
																	onMouseLeave={() => setIsHoveredPlus(false)}
																	onClick={addResource}
																	disabled={(initialValues?.status === 'for approved' || initialValues?.status === 'approved' || initialValues?.status === 'rejected') && !canEditStatus}
																>
																	Agregar
																	<AppGetIcon
																		name="plus"
																		strokeColor="#042F53"
																		hoverColor={isHoveredPlus ? '#FFF' : undefined}
																	/>
																</button>
															</div>
														</div>
													</>
												)}
												<h4 className={style.under__line}>Recursos seleccionados</h4>
												{messageResource && (<div
													style={{
														width: '100%',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}>
													<AppGetIcon name="infoCircle" />{messageResource}
												</div>)}
												<div className={style.form__container_row}>
													<div className={style.container_blocks}>
														{selectedResources.length > 0 ? (
															selectedResources.map((resource: any, index: number) => (
																<div key={`${resource.id}-${index}`} className={style.resource_card}>
																	{/* Contenido del recurso */}
																	<div className={style.resource_card_content}>
																		<strong>{resource.campusId}-{resource.buildingId}-{resource.name}</strong> ({resource.type})
																	</div>

																	{/* Icono para eliminar el recurso */}
																	{(editForm || createForm) && (
																		<div className={style.resource_card_actions}>
																			{((!canEditStatus && (initialValues?.status === 'pending' || initialValues?.status === '')) ||
																				(canEditStatus && (initialValues?.status === 'for approved' || initialValues?.status === 'pending'))) && (
																					<AppGetIcon
																						onMouseEnter={() => setIsHovereDelete(true)}
																						onMouseLeave={() => setIsHovereDelete(false)}
																						style={{ width: '25px', height: '25px' }}
																						onClick={() => removeResource(resource.id)}
																						name="trash"
																						strokeColor="#5B5F62"
																						hoverColor={isHoveredDelete ? '#962330' : undefined}
																					/>
																				)}
																		</div>
																	)}

																</div>
															))
														) : (
															<div
																style={{
																	width: '100%',
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																}}>
																<AppGetIcon name="infoCircle" /> No tienes recursos agregados
															</div>
														)}
													</div>


												</div>
												{isDisabledCampus && (
													<div
														style={{
															width: '100%',
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
														}}>
														<AppGetIcon name="infoCircle" /> Tienes recursos agregados donde el campus está deshabilitado
													</div>
												)}

												{values.status !== 'pending' && (
													<h4 className={style.under__line}>Respuesta de solicitud</h4>
												)}
												<div style={{ width: '100%', minWidth: '260px' }}>
													{/* Mostrar el InputTextArea solo si estás editando o creando, tienes permisos y el estado es 'for approved' */}
													{canEditStatus && (editForm || createForm) && values.status === 'for approved' ? (
														""
													) : (
														/* Mostrar el comentario de rechazo solo si no estás creando o editando y el estado es 'approved' o 'rejected' */
														(values.status === 'approved' || values.status === 'rejected' || values.status === 'rejected - DGDE') && (
															<div className={style.row__item}>
																<div>{values.rejectionComment ? values.rejectionComment : 'N/A'}</div>
															</div>
														)
													)}
												</div>
												{values.approverDGDEName && (
													<>
														<h4 className={style.under__line}>Aprobo/Rechazo DGDE</h4>
														<div style={{ width: '100%', minWidth: '260px' }}>
															<div className={style.row__item}>
																<div>{values.approverDGDEName ? values.approverDGDEName : 'N/A'}</div>
															</div>
														</div>
													</>
												)}
											</>
										)}

										<div className={styleForm.containerButtoms}>
											{(editForm || createForm) && (
												<button
													className={styleButton.Buttom_option}
													onMouseEnter={() => setIsHoveredCancel(true)}
													onMouseLeave={() => setIsHoveredCancel(false)}
													type="button"
													onClick={() => {
														setEditForm(false);
														setCreateForm(false);
														setInitialValues({
															id: '',
															numberAttendees: '',
															eventName: '',
															startDate: '',
															endDate: '',
															status: '',
															requestedBy: '',
															requesterRoleId: null,
															approvedBy: '',
															approvalDate: '',
															rejectionComment: '',
															roomType: '',
															responsibles: [],
															rooms: [],
															intervals: [],
															approverDGDEName: '',
															approvedByDGDE: null

														});
														setTypeResourceId({ label: '', value: '' });
														setCampus({ label: '', value: '' }); // Resetea el valor de "campus".
														setBuildingId({ label: '', value: '' }); // Resetea el valor de "edificio".
														setResourceId({ label: '', value: '' });
														setHoursBlockCreated([]);
														setSelectedResources([]);
													}}>
													<AppGetIcon
														classIcon={styleDataTable.sizeIcon}
														name="cancel"
														hoverColor={isHoveredCancel ? '#FFF' : ''}
													/> 	Cancelar
												</button>
											)}

											{(editForm || createForm) && !values.id && (
												<button
													type="button"
													className={styleButton.Buttom_option}
													onClick={() => handleSubmitBasicInfo(values)}
													onMouseEnter={() => setIsHoveredSave(true)}
													onMouseLeave={() => setIsHoveredSave(false)}
												>
													<AppGetIcon
														classIcon={styleDataTable.sizeIcon}
														name="check"
														hoverColor={isHoveredSave ? '#FFF' : ''}
													/>
													Guardar y continuar
												</button>
											)}
											{(!editForm && !createForm && dataInitDataStore.rolSelected !== 55 &&
	dataInitDataStore.rolSelected !== 13 &&
	!(initialValues.requesterRoleId === 13 && initialValues?.status === 'pending')
) && (
	initialValues?.status !== 'for approved' &&
	initialValues?.status !== 'pending - DGDE' &&
	initialValues?.status !== 'approved' &&
	initialValues?.status !== 'rejected' &&
	values.id
) && (
	<button
		type="button"
		className={styleButton.Buttom_option}
		onMouseEnter={() => setIsHoveredRequest(true)}
		onMouseLeave={() => setIsHoveredRequest(false)}
		disabled={
			values.responsibles.length < 0 &&
			values.rooms.length < 0 &&
			values.intervals.length < 0
		}
		onClick={() => {
			setIsHoveredRequest(false);
			setIsOpenConfirm('for approved');
		}}
	>
		<AppGetIcon
			classIcon={styleDataTable.sizeIcon}
			name="productRequest"
			hoverColor={isHoveredRequest ? '#FFF' : ''}
		/>
		Solicitar aprobación
	</button>
)}

											{(!editForm && !createForm && dataInitDataStore.rolSelected !== 55) && (
												initialValues?.status !== 'for approved' &&
												initialValues?.status !== 'approved' &&
												initialValues?.status !== 'pending - DGDE' &&
												initialValues?.status !== 'rejected' &&
												values.id
											) && (
													<>
														{dataInitDataStore.rolSelected === 13 ? (
															<button
																type="button"
																className={styleButton.Buttom_option}
																onMouseEnter={() => setIsHoveredRequest(true)}
																onMouseLeave={() => setIsHoveredRequest(false)}
																disabled={values.responsibles.length < 0 &&
																	values.rooms.length < 0 &&
																	values.intervals.length < 0}
																onClick={() => { setIsHoveredRequest(false); setIsOpenConfirm('pending - DGDE') }}>
																<AppGetIcon
																	classIcon={styleDataTable.sizeIcon}
																	name="productRequest"
																	hoverColor={isHoveredRequest ? '#FFF' : ''}
																/>
																Solicitar aprobación DGDE
															</button>
														) : (
															null
														)
														}
													</>
												)}






											{(editForm || createForm) &&
												(
													initialValues?.status !== 'for approved' &&
													initialValues?.status !== 'approved' &&
													initialValues?.status !== 'rejected' &&
													values.id
												) && (
													<button
														type="button"
														onClick={() => handleSubmitResource(values, setFieldValue)}
														onMouseEnter={() => setIsHoveredSave(true)}
														onMouseLeave={() => setIsHoveredSave(false)}
														className={styleButton.Buttom_option}>

														<AppGetIcon
															classIcon={styleDataTable.sizeIcon}
															name="check"
															hoverColor={isHoveredSave ? '#FFF' : ''}
														/>
														Guardar cambios
													</button>
												)}

											{canEditStatus && editForm && values.status === 'for approved' && (
												<button
													type="button"
													onClick={() => handleUpdateResource(values, setFieldValue)}
													onMouseEnter={() => setIsHoveredSave(true)}
													onMouseLeave={() => setIsHoveredSave(false)}
													className={styleButton.Buttom_option}>
													<AppGetIcon
														classIcon={styleDataTable.sizeIcon}
														name="check"
														hoverColor={isHoveredSave ? '#FFF' : ''}
													/>
													Guardar
												</button>
											)}
											{canEditStatus && !editForm && values.status === 'for approved' &&
												initialValues?.rooms?.every(room =>
													dataInitDataStore.userCampus.some(userCampus => String(userCampus.campusId) === String(room.campusId))
												) ? (
												<>
													<button
														type="button"
														className={styleForm.buttomFooter + ' ' + styleForm.buttom_cancelar}
														onClick={() => {
															setShowInput(true); // Mostramos el input al hacer clic en Rechazar
															setIsOpenConfirm('rejected')
														}}>
														<AppGetIcon
															classIcon={styleDataTable.sizeIcon}
															name="xCircleFill"
															strokeColor='rgb(150, 35, 48)'
															fillColor='rgb(150, 35, 48)'
															hoverColor={isHoveredSave ? '#FFF' : ''}
														/>

														Rechazar
													</button>
													<button
														type="button"
														className={styleForm.buttomFooter + ' ' + styleForm.buttom_cancelar}
														style={{ border: '1px solid #15CB20', color: '#15CB20' }}
														onClick={() => setIsOpenConfirm('approved')}>
														<AppGetIcon
															classIcon={styleDataTable.sizeIcon}
															name="checkCircleFill"
															hoverColor={isHoveredSave ? '#FFF' : ''}
														/>
														Aprobar
													</button>
												</>
											) : (
												<></>
											)}
											{dataInitDataStore.rolSelected === 55 && !editForm && values.status === 'pending - DGDE' &&
												initialValues?.rooms?.every(room =>
													dataInitDataStore.userCampus.some(userCampus => String(userCampus.campusId) === String(room.campusId))
												) ? (
												<>
													<button
														type="button"
														className={styleForm.buttomFooter + ' ' + styleForm.buttom_cancelar}
														onClick={() => {
															setShowInput(true); // Mostramos el input al hacer clic en Rechazar
															setIsOpenConfirm('rejected - DGDE')
														}}>
														<AppGetIcon
															classIcon={styleDataTable.sizeIcon}
															name="xCircleFill"
															strokeColor='rgb(150, 35, 48)'
															fillColor='rgb(150, 35, 48)'
															hoverColor={isHoveredSave ? '#FFF' : ''}
														/>

														Rechazar
													</button>
													<button
														type="button"
														className={styleForm.buttomFooter + ' ' + styleForm.buttom_cancelar}
														style={{ border: '1px solid #15CB20', color: '#15CB20' }}
														onClick={() => setIsOpenConfirm('for approved')}>
														<AppGetIcon
															classIcon={styleDataTable.sizeIcon}
															name="checkCircleFill"
															hoverColor={isHoveredSave ? '#FFF' : ''}
														/>
														Aprobar
													</button>
												</>
											) : (
												<></>
											)}
										</div>
									</Form>
								)}
							</Formik>
						)}
					</div>
				</div>


				<Modal
					title={`Confirmación de Eliminación`}
					isOpen={isOpenCurrentDelete}
					onClose={handleCloseModalDelete}
					size={{ width: '60%', height: '' }}
					footerContent={
						<div className={style.containerButtoms}>
							<button
								className={`${styleForm.buttomFooter} ${styleForm.buttom_cancelar}`}
								onClick={() => setIsOpenCurrentDelete(null)}>
								Cancelar
							</button>
							<button
								type="button"
								className={styleForm.buttomFooter}
								onClick={handleDelete}
								disabled={loadingForm}>
								Si, Eliminar
							</button>
						</div>
					}>
					<div style={{ padding: '30px' }}>
						<center>
							{`¿Estás seguro de que deseas eliminar la reserva ${isOpenCurrentDelete?.eventName}? Esta acción no se puede deshacer.`}
						</center>
					</div>
				</Modal>

				<Modal
					title="Confirmación de Envío"
					isOpen={Boolean(isOpenConfirm ?? ''.length > 0)}
					onClose={handleCloseModalConfirm}
					size={{ width: '60%', height: '' }}
					footerContent={
						<div className={style.containerButtoms}>
							{/* Botón de Cancelar */}
							<button
								className={`${styleForm.buttomFooter} ${styleForm.buttom_cancelar}`}
								onClick={() => {
									setShowInput(false); // Oculta el input cuando se cancela
									setIsOpenConfirm(''); // Cierra el modal
								}}
							>
								Cancelar
							</button>

							{/* Botón de Confirmar */}
							<button
								type="button"
								className={styleForm.buttomFooter}
								onClick={handleConfirm}
								disabled={loadingForm}>
								Si, Confirmar
							</button>
						</div>
					}
				>
					<div style={{ padding: '30px' }}>
						<center>
							{`¿Estás seguro de que deseas confirmar esta opción en la reserva? Esta acción no se puede deshacer.`}
						</center>

						{/* Muestra el campo de texto solo si showInput es true */}
						{showInput && (
							<div style={{ marginTop: '20px', width: '100%', minWidth: '260px' }}>
								<label htmlFor="rejectionComment">Razon del rechazo</label>
								<InputTextArea
									name="rejectionComment"
									placeholder="Ingresa una descripción sobre esta solicitud"
									value={rejectionReason} // Controlado por el estado rejectionReason
									onChange={(e) => setRejectionReason(e.target.value)} // Actualiza el estado cuando el usuario escribe
									classNameInput={styleForm.input__input}
								/>
							</div>
						)}
					</div>
				</Modal>

				{
					responseMessage?.code === 2 && (
						<Modal
							title={`Respuesta de solicitud`}
							isOpen={isResponseModalOpen}
							onClose={() => setIsResponseModalOpen(false)}
							size={{ width: '90%', height: '', maxWidth: '1000px' }}
							footerContent={
								<div className={style.containerButtoms}>
									<button
										className={`${styleForm.buttomFooter} ${styleForm.buttom_cancelar}`}
										onClick={() => setIsResponseModalOpen(false)}>
										Cancelar
									</button>
								</div>
							}>
							<div style={{ padding: '10px' }}>
								<div className={style.containerResponseData}>
									<div className={style.responseMessagens}>
										<AppGetIcon width='45' height='45' strokeColor='#ff0000' name="infoCircle" />

										{responseMessage?.message}

									</div>
									<div>
										<strong>Detalles del Conflicto:</strong>
										<ul>
											{responseMessage?.data?.map((item: { name: string, startDate: string, endDate: string }, index: number) => (
												<li key={index}>{`${item.name}: ${item.startDate ? hFormatDate(item.startDate, 'yyyy-MM-dd') : ''} - ${item.endDate ? hFormatDate(item.endDate, 'yyyy-MM-dd') : ''}`}</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						</Modal>
					)
				}
				{
					responseMessage?.code === 3 && (
						<Modal
							title={`Respuesta de solicitud`}
							isOpen={isResponseModalOpen}
							onClose={() => setIsResponseModalOpen(false)}
							size={{ width: '90%', height: '', maxWidth: '1000px' }}
							footerContent={
								<div className={style.containerButtoms}>
									<button
										className={`${styleForm.buttomFooter} ${styleForm.buttom_cancelar}`}
										onClick={() => setIsResponseModalOpen(false)}>
										Cancelar
									</button>
								</div>
							}>
							<div style={{ padding: '10px' }}>
								<div className={style.containerResponseData}>
									<div className={style.responseMessagens}>
										{/* Aplica el color rojo también al ícono */}
										<AppGetIcon width='45' height='45' strokeColor='#ff0000' name="infoCircle" />

										{responseMessage?.message}
									</div>
									<div>
										<strong>Detalles del Conflicto:</strong>
										<ul>
											{responseMessage?.data?.map((item: { name: string, startDate: string, endDate: string, roomWithConflict: number }, index: number) => (
												<li key={index}>{`${item.name}: ${item.startDate ? hFormatDate(item.startDate, 'yyyy-MM-dd') : ''} - ${item.endDate ? hFormatDate(item.endDate, 'yyyy-MM-dd') : ''} (Sala en conflicto: ${item.roomWithConflict})`}</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						</Modal>
					)
				}
				{
					responseMessage?.code === 4 && (
						<Modal
							title={`Respuesta de solicitud`}
							isOpen={isResponseModalOpen} // Usar el estado para manejar la visibilidad del modal
							onClose={() => setIsResponseModalOpen(false)} // Cerrar el modal
							size={{ width: '90%', height: '', maxWidth: '1000px' }}
							footerContent={
								<div className={style.containerButtoms}>
									<button
										className={`${styleForm.buttomFooter} ${styleForm.buttom_cancelar}`}
										onClick={() => setIsResponseModalOpen(false)}>
										Cancelar
									</button>
								</div>
							}>
							<div style={{ padding: '10px' }}>
								<div className={style.containerResponseData}>
									<div className={style.responseMessagens}>
										{/* Aplica el color rojo también al ícono */}
										<AppGetIcon width='45' height='45' strokeColor='#ff0000' name="infoCircle" />
										{responseMessage?.message}
									</div>

									{/* Detalle de Conflictos Banner */}
									{responseMessage?.data?.bannerConflict && responseMessage.data.bannerConflict.length > 0 && (
										<div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
											<h4 className={style.under__line}>
												<strong>Detalle de Conflictos Banner:</strong>
											</h4>
											<div className={style.responseDataList}>
												{responseMessage.data.bannerConflict.slice(0, 3).map((item: any, index: number) => (
													<div key={index} className={style.responseDataItem}>
														<h4>Tipo: {item.TIPO}</h4>
														<div style={{ display: 'flex', flexWrap: 'wrap' }}>
															<div
																style={{
																	display: 'flex',
																	alignItems: 'center',
																	backgroundColor: '#e0e0e0',
																	borderRadius: '16px',
																	padding: '0.25rem 0.5rem',
																	margin: '0.25rem',
																	fontSize: '0.875rem',
																	flexWrap: 'wrap',
																}}
															>
																{[
																	`Periodo: ${item.PERIODO}`,
																	`NRC Evento: ${item.NRC_EVENTO}`,
																	`Fecha Inicio: ${item.FECHA_INICIO ? hFormatDate(item.FECHA_INICIO, 'yyyy-MM-dd') : ''}`,
																	`Fecha Término: ${item.FECHA_TERMINO ? hFormatDate(item.FECHA_TERMINO, 'yyyy-MM-dd') : ''}`,
																	`Día de la Semana: ${item.DIA_SEMANA}`,
																	`Hora Inicio: ${item.HORA_INICIO}`,
																	`Hora Término: ${item.HORA_TERMINO}`,
																	`Edificio: ${item.EDIFICIO}`,
																	`Sala: ${item.SALA}`,
																	`Bloques horarios: ${item.intervals
																		.map((interval: any) => `(Inicio: ${interval.startHourBlock}, Fin: ${interval.endHourBlock})`)
																		.join(', ')}`,
																].join(', ')}
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
									)}

									{/* Detalle de Conflictos Booking */}
									{responseMessage?.data?.bookingConflict && responseMessage.data.bookingConflict.length > 0 && (
										<div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
											<h4 className={style.under__line}>
												<strong>Detalle de Conflictos Booking:</strong>
											</h4>
											<div className={style.responseDataList}>
												{responseMessage.data.bookingConflict.slice(0, 3).map((item: any, index: number) => (
													<div key={index} className={style.responseDataItem}>
														<h4>Reserva: {item.eventName}</h4>
														<div style={{ display: 'flex', flexWrap: 'wrap' }}>
															<div
																style={{
																	display: 'flex',
																	alignItems: 'center',
																	backgroundColor: '#e0e0e0',
																	borderRadius: '16px',
																	padding: '0.25rem 0.5rem',
																	margin: '0.25rem',
																	fontSize: '0.875rem',
																	flexWrap: 'wrap',
																}}
															>
																{[
																	`Campus: ${item.Campus}`,
																	`Edificio: ${item.Edificio}`,
																	`Salón: ${item.Salon}`,
																	`Fecha Inicio: ${item.startDate ? hFormatDate(item.startDate, 'yyyy-MM-dd') : ''}`,
																	`Fecha Fin: ${item.endDate ? hFormatDate(item.endDate, 'yyyy-MM-dd') : ''}`,
																	`Evento: ${item.eventName}`,
																	`Tipo: ${item.tipo}`,
																	`Bloques horarios: ${item.intervals
																		.map((interval: any) => `(Inicio: ${interval.startHourBlock}, Fin: ${interval.endHourBlock})`)
																		.join(', ')}`,
																].join(', ')}
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
									)}





								</div>
							</div>
						</Modal>
					)
				}
				{
					responseMessage?.code === 5 && (
						<Modal
							title={`Respuesta de solicitud`}
							isOpen={isResponseModalOpen}
							onClose={() => setIsResponseModalOpen(false)}
							size={{ width: '90%', height: '', maxWidth: '1000px' }}
							footerContent={
								<div className={style.containerButtoms}>
									<button
										className={`${styleForm.buttomFooter} ${styleForm.buttom_cancelar}`}
										onClick={() => setIsResponseModalOpen(false)}>
										Cancelar
									</button>
								</div>
							}>
							<div style={{ padding: '10px' }}>
								<div className={style.containerResponseData}>
									<div className={style.responseMessagens}>
										<AppGetIcon width='45' height='45' strokeColor='#ff0000' name="infoCircle" />

										{responseMessage?.message}

									</div>
									<div>
										<strong>Detalles del Conflicto:</strong>
										<ul>
											{responseMessage?.error ?
												<li>{`${responseMessage?.error.code}: ${responseMessage?.error.errorNum} - ${responseMessage?.error.offset}`}</li> : ''
											}
										</ul>
									</div>
								</div>
							</div>
						</Modal>
					)
				}


			</div >
			);
}
