
import { useEffect, useState } from "react";
import { Field, Formik, Form } from "formik";
import * as yup from "yup";
import { shallow } from "zustand/shallow";
import AppGetIcon from "../../../../common/UI/AppGetIcon";
import InputDateFormik from '../../../../common/UI/Inputs/InputDateFormik';
import Modal from "../../../../common/UI/DataTable/modal/modal";
import DateInput from "../../../../common/UI/Inputs/InputDate";
import ConatinerFilter from "../../../../common/UI/DataTable/filterModal/containerFilter";
import DataTable from "../../../../common/UI/DataTable/dataTable";
import { InputText } from "../../../../common/UI/Inputs";

import { useUserStore } from "../../../../store/user.store";

import useFormattedDate from "../../../../common/hooks/useFormattedDate";

import style from "./GeneralReservationLimit.module.css";
import styleForm from "../../../../common/theme/styles/form.module.css";
import styleDataTable from "../../../../common/UI/DataTable/dataTable.module.css";

import { GetGeneralReservationLimitWithPaginationService } from "../../services/GeneralReservationLimit/getGeneralReservationLimitWithPagination.service";
import { CreateOrUpdateGeneralReservationLimitService, IReservationsLimit } from "../../services/GeneralReservationLimit/createOrUpdateGeneralReservationLimit.service";
import { DeleteGeneralReservationLimitService } from "../../services/GeneralReservationLimit/deleteGeneralReservationLimit.service";
import InputSearch from "../../../../common/UI/DataTable/inputSearch";
import { DataSelectDto } from "../../../../common/dtos/general.dto";

const getGeneralReservationLimitWithPaginationService = new GetGeneralReservationLimitWithPaginationService();
const createOrUpdateGeneralReservationLimitService = new CreateOrUpdateGeneralReservationLimitService();
const deleteGeneralReservationLimitService = new DeleteGeneralReservationLimitService();

export default function GeneralReservationLimitPage() {
  const hFormatDate = useFormattedDate();



  const [isReloadData, setIsReloadData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [isOpenCurrentDelete, setIsOpenCurrentDelete] = useState<any>(null);
  const [isHoveredEdit, setIsHoveredEdit] = useState<number | null>(null);
  const [isHoveredDelete, setIsHoveredDelete] = useState<number | null>(null);
  const [initialData, setInitialData] = useState<IReservationsLimit | null>(null);
  const [statusFilter, setStatusFilter] = useState<DataSelectDto>({
    label: "",
    value: "",
  });
  const dataUserStore = useUserStore(
    state => ({
      userId: state.dataUser
    }),
    shallow
  );

  const getStatusOptions = () => {
    return [
      { label: "Activo", value: "1" },
      { label: "Inactivo", value: "0" },
    ];
  };

  const handleDelete = async () => {
    setLoading(true);
    let params: any = {
      generalReservationLimitId: isOpenCurrentDelete?.id
    };
    try {
      await deleteGeneralReservationLimitService.run(params); // Llama al servicio de eliminación con el ID del elemento seleccionado
      setIsReloadData(!isReloadData); // Recarga los datos después de la eliminación
      setIsOpenCurrentDelete(null); // Cierra el modal
      setLoading(false);
    } catch (error) {
      console.error("Error al eliminar la reserva:", error);
      setLoading(false);
    }
  };

  const handleCloseModalDelete = () => {
    setIsOpenCurrentDelete(null);
  };

  const columns = [

    {
      header: "NOMBRE",
      field: "name",
    },

    {
      header: "FECHA INICIO",
      field: "",
      render: (row: any) => {
        return (
          <>
            {row.startDate ? (
              <div>{hFormatDate(row.startDate, "dd-MM-yyyy")}</div>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      header: "FECHA FIN",
      field: "",
      render: (row: any) => {
        return (
          <>
            {row.endDate ? (
              <div>{hFormatDate(row.endDate, "dd-MM-yyyy")}</div>
            ) : (
              ""
            )}
          </>
        );
      },
    },

    {
      header: "ESTADO",
      field: "active",
      render: (row: any) => {
        return row.active === 1 ? 'Activo' : 'Inactivo';
      },
    },
    {
      header: "OPCIONES",
      field: "",
      render: (row: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              gap: "5px",
            }}
          >
            <button
              className={styleDataTable.buttom_option}
              onClick={() => handleEditUser(row)}
              onMouseEnter={(e) => { setIsHoveredEdit(row?.id); }}
              onMouseLeave={(e) => { setIsHoveredEdit(null); }}
            >
              <AppGetIcon name="edit" classIcon={styleDataTable.sizeIcon} strokeColor="#962330" hoverColor={isHoveredEdit === row.id ? '#FFF' : undefined} /> Editar
            </button>

            <button
              className={styleDataTable.buttom_option}
              onClick={() => setIsOpenCurrentDelete(row)}
              onMouseEnter={(e) => { setIsHoveredDelete(row?.id); }}
              onMouseLeave={(e) => { setIsHoveredDelete(null); }}
            >
              <AppGetIcon
                name="trash"
                classIcon={styleDataTable.sizeIcon}
                strokeColor="#962330"
                hoverColor={isHoveredDelete === row?.id ? "#FFF" : ""}
              />{" "}
              Eliminar
            </button>
          </div>
        );
      },
    },
  ];
  const reservationLimitSchema = yup.object().shape({
    name: yup.string().required("Nombre es requerido"),
    active: yup.string().required("Estado es requerido"),
    startDate: yup.string().required("Fecha inicio es requerido"),
    endDate: yup.string().required("Fecha fin es requerido"),
  });
  const handleSubmit = async (values: IReservationsLimit, actions: any) => {
    if (actions.errors && Object.keys(actions.errors).length !== 0) {
      actions.setSubmitting(false);
      return;
    };

    // Convertir las fechas a "yyyy-MM-dd" antes de enviarlas al backend
    const formattedStartDate = hFormatDate(values.startDate, "yyyy-MM-dd");
    const formattedEndDate = hFormatDate(values.endDate, "yyyy-MM-dd");

    let dataCreated: any = {
      ...values,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      active: values.active ? 1 : 0,
      lastModifiedUserId: dataUserStore.userId.id
    };

    try {
      await createOrUpdateGeneralReservationLimitService.run(dataCreated);

      setInitialData(null);
      setIsReloadData(!isReloadData);
      setIsOpen(false);
    } catch (error) {
      console.error("Error guardando el recurso", error);
    }
  };

  const handleCloseModal = () => {
    setInitialData(null);  // Limpia los datos iniciales
    setIsOpen(false);
  };
  const handleEditUser = async (row: IReservationsLimit) => {
    // Convertir las fechas al formato "dd/MM/yyyy"
    const formattedStartDate = hFormatDate(row?.startDate, "dd/MM/yyyy");
    const formattedEndDate = hFormatDate(row?.endDate, "dd/MM/yyyy");

    // Establecer los datos iniciales incluyendo las fechas formateadas
    setInitialData({
      id: row?.id ?? null,
      name: row?.name ?? '',
      active: row?.active ? true : false,
      lastModifiedUserId: row?.lastModifiedUserId ?? null,
      startDate: formattedStartDate ?? '',
      endDate: formattedEndDate ?? ''
    });
    setIsOpen(true);
  };





  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const [startDate, setStartDate] = useState<any>(startOfMonth);
  const [endDate, setEndate] = useState<any>(endOfMonth);

  const handleFilterButtonClick = () => {
    setShowFilterModal(true);
  };

  const handleFilterModalClose = () => {
    setShowFilterModal(false);
  };

  const [filterKey, setFilterKey] = useState(0); // Estado para forzar la actualización de DateInput

  const handleDeleteFilter = () => {
    setStartDate(null);
    setEndate(null);
    setFilterKey((prevKey) => prevKey + 1); // Forzar la actualización de DateInput
  };

  const addButton = async () => {
    setIsOpen(true);
  };

  useEffect(() => {
    setIsReloadData(!isReloadData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, statusFilter]);

  return (
    <div className={style.containerQr}>
      <DataTable
        title="Limitaciones Generales"
        columns={columns}
        service={getGeneralReservationLimitWithPaginationService}
        params={{
          startDate: startDate ? hFormatDate(startDate, "yyyy-MM-dd") : "",
          endDate: endDate ? hFormatDate(endDate, "yyyy-MM-dd") : "",
          active: statusFilter.value,
        }}
        reloadData={isReloadData}
        addButton={addButton}
      >
        <ConatinerFilter
          nameIcon="filter"
          onClick={() => handleFilterButtonClick()}
          isOpen={showFilterModal}
          onClose={handleFilterModalClose}
          handleDeleteFilter={handleDeleteFilter}
        >
          <div
            className={style.container__select}
            style={{ minWidth: "280px" }}
          >
            <DateInput
              key={filterKey} // Utiliza la clave para forzar la actualización del componente
              name="fecha"
              textLabel="Selecionar rango"
              classNameInput={styleForm.input__input}
              initialValue={[startDate, endDate]}
              range={true}
              dateFormat="dd-MM-yyyy"
              onChange={(dateSelected: any) => {
                setStartDate(dateSelected[0]);
                setEndate(dateSelected[1]);
              }}
            />
            <div></div>
          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "280px" }}
          >
            <InputSearch
              key={Math.random()}
              name="status"
              classNameInput={styleForm.input__input}
              textLabel="Selecciona estado"
              data={getStatusOptions()}
              onSelect={(selected) => {
                setStatusFilter(selected);
              }}
              selected={statusFilter}
            />
          </div>

        </ConatinerFilter>
      </DataTable>
      <Modal title={initialData?.id ? 'Editar limitación general' : `Crear limitación general`} isOpen={isOpen} onClose={handleCloseModal} size={{ width: '500px', height: '' }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
          <Formik
            initialValues={{
              id: initialData?.id ?? null,
              name: initialData?.name ?? '',
              active: initialData?.active ? true : false,
              lastModifiedUserId: initialData?.lastModifiedUserId ?? null,
              startDate: hFormatDate(initialData?.startDate, "yyyy-MM-dd") ?? '',
              endDate: hFormatDate(initialData?.endDate, "yyyy-MM-dd") ?? '',
            }}
            validationSchema={reservationLimitSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, errors }) => (
              <Form className="flex-row" style={{ width: '100%' }}>
                <InputText
                  type="text"
                  classNameInput={styleForm.input__input}

                  name="name"
                  placeholder="Nombre"
                  TextLabel="Nombre"
                />
                <div className={style.form__container_row}>
                  <InputDateFormik
                    TextLabel="Fecha inicio*"
                    name="startDate"
                    dateFormat="dd/MM/yyyy"  // Asegurarse de usar el formato correcto
                  />
                  <InputDateFormik
                    TextLabel="Fecha fin*"
                    name="endDate"
                    dateFormat="dd/MM/yyyy"  // Asegurarse de usar el formato correcto

                  />
                </div>


                <div>
                  <label className={style.title}>Estado</label>
                  <Field
                    name="active"
                    render={({ field }: any) => (
                      <>
                        <input
                          {...field}
                          id="active"
                          type="checkbox"
                          checked={field.value}
                        />
                      </>
                    )}
                  />
                </div>
                <div className={style.containerButtoms}>
                  <button
                    className={styleForm.buttomFooter + ' ' + styleForm.buttom_cancelar}
                    onClick={handleCloseModal}
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className={styleForm.buttomFooter}

                    disabled={isSubmitting || (errors && Object.keys(errors).length !== 0)}
                  >
                    Guardar
                  </button>
                </div>

              </Form>
            )}
          </Formik>
        </div>

      </Modal>
      <Modal
        title={`Confirmación de Eliminación`}
        isOpen={isOpenCurrentDelete}
        onClose={handleCloseModalDelete}
        size={{ width: "500px", height: "" }}
        footerContent={
          <div className={style.containerButtoms}>
            <button
              className={styleForm.buttomFooter + ' ' + styleForm.buttom_cancelar}
              onClick={handleCloseModalDelete}
            >
              Cancelar
            </button>
            <button
              type="button"
              className={styleForm.buttomFooter}
              onClick={handleDelete}
              disabled={loading}
            >
              Si, Eliminar
            </button>
          </div>
        }
      >
        <div style={{ padding: "30px" }}>
          <center>
            {`¿Estás seguro de que deseas eliminar la limitación general ${isOpenCurrentDelete?.name}? Esta acción no se puede deshacer.`}
          </center>
        </div>
      </Modal>
    </div>
  );
}
