import React, { useState, useEffect, useRef } from 'react';

import AppGetIcon from '../AppGetIcon';
import ProgressBar from '../ProgressBar';
import Search from '../DataTable/search/search';

import { useDataTableStore } from '../../../store/appDataItems.storage';

import useDebounce from '../../hooks/useDebounce';

import styles from './AppItemsWithPagination.module.css';
import styleDataTable from '../DataTable/dataTable.module.css';

interface ItemsWithPaginationProps {
  title?: string;
  service: any;
  params?: any;
  children: (data: any) => React.ReactElement;
  addButton?: () => void;
  editButton?: () => void;
  filterControls?: React.ReactNode; // Prop adicional para controles de filtro u otros elementos
  refreshKey: any;
  moreActions?: React.ReactNode; // Prop adicional para controles de filtro u otros elementos
  importExcel?: (value: any) => void;
};

const AppItemsWithPagination: React.FC<ItemsWithPaginationProps> = ({
  title, service, params, children, addButton, moreActions, editButton, filterControls, refreshKey, importExcel
}) => {
  const {
    data,
    isLoading,
    setPage,
    setPerPage,
    search,
    setSearch,
    fetchData
  } = useDataTableStore();
  const debouncedInputValue = useDebounce(search, 500);

  const page = data.page ?? 1;
  const perPage = data.perPage ?? 10;
  const total = data.total ?? 0;

  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredEdit, setIsHoveredEdit] = useState(false);
  const [isHoveredSearch, setIsHoveredSearch] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSearchChange = (value: string) => {
    setSearch(value);
    setPage(1);
  };

  const handleSearchClear = () => {
    setSearch('');
    setPage(1);
  };

  const handleSearchClose = () => {
    setOpenSearch(false);
  };

  useEffect(() => {
    fetchData(service, { ...params, page, perPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputValue, refreshKey]);


  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && importExcel) {
      importExcel(file);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };
  return (
    <div style={{ width: '100%' }}>
      {isLoading && <ProgressBar width='calc(100% - 0px)' height='calc(100vh - 57px)' />}
      <div className={styles.container__componente}>
        <div className={styles.container__componente_head}>
          <div className={styles.header__title}>{title ?? ''}</div>
          <div className={styles.header__options}>

            <div className={styles.header__options_actions}>
              {filterControls}
              {
                !openSearch ?
                  <div
                    className={styleDataTable.buttom_option}
                    onClick={() => setOpenSearch(true)}
                    onMouseEnter={() => setIsHoveredSearch(true)}
                    onMouseLeave={() => setIsHoveredSearch(false)}
                  >
                    <AppGetIcon
                      name='search'
                      classIcon={styleDataTable.sizeIcon}

                      strokeColor='#962330'
                      fillColor='#962330'
                      hoverColor={isHoveredSearch ? '#FFF' : undefined}
                    />
                    Buscar
                  </div>
                  :
                  <div>
                    <Search
                      onChange={handleSearchChange}
                      onClear={handleSearchClear}
                      closeSearch={handleSearchClose}
                      valueInput={search}
                    />
                  </div>
              }
              {
                addButton &&
                <div
                  className={styleDataTable.buttom_option}
                  onClick={addButton}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <AppGetIcon
                    name='plus'
                    classIcon={styleDataTable.sizeIcon}
                    hoverColor={isHovered ? '#FFF' : undefined}
                  />
                  Crear
                </div>
              }
              {
                editButton &&
                <div
                  className={styleDataTable.buttom_option}
                  onClick={editButton}
                  onMouseEnter={() => setIsHoveredEdit(true)}
                  onMouseLeave={() => setIsHoveredEdit(false)}
                >
                  <AppGetIcon
                    name='edit'
                    classIcon={styleDataTable.sizeIcon}
                    hoverColor={isHoveredEdit ? '#FFF' : undefined}
                  />
                  Editar
                </div>
              }

              {
                moreActions
              }
              {importExcel && (
                <div className={styleDataTable.button_option} onClick={triggerFileInput} onMouseEnter={() => setIsHoveredEdit(true)} onMouseLeave={() => setIsHoveredEdit(false)}>
                  <AppGetIcon name='excel' classIcon={styleDataTable.sizeIcon} hoverColor={isHoveredEdit ? '#FFF' : undefined} />
                  Importar
                  <input ref={fileInputRef} type="file" className="custom-file-input" id="customFile" onChange={handleFileInput} accept=".xls, .xlsx" style={{ display: 'none' }} />
                </div>
              )}
            </div>
            <div className={styles.pagination}>
              <button onClick={() => setPage(page - 1)} disabled={page <= 1}>
                <AppGetIcon name='arrow-back' />
              </button>
              <span>Página {data.page}</span>
              <button onClick={() => setPage(page + 1)} disabled={page >= Math.ceil(total / perPage)}>

                <AppGetIcon name='arrow-next' />
              </button>

              <select value={perPage.toString()} onChange={(e) => setPerPage(parseInt(e.target.value))}>
                <option value='10'>10</option>
                <option value='20'>20</option>
                <option value='50'>50</option>

              </select>
            </div>
          </div>
        </div>

        <div className={styles.container__componente_body}>
          {
            !isLoading &&
              data.data.length > 0 ? data.data.map((item: any) => children(item))
              :
              <div className={styles.Container__notFound}>
                <div className={styles.Container__notFound} ><AppGetIcon name='infoCircle' />  <span>No se encontró información.</span></div>
              </div>
          }
        </div>
        <div className={styles.container__componente_footer}>
          <div>
            Mostrando 1 a {data.perPage} de {data?.total} registros
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppItemsWithPagination;
