
import { useEffect, useState } from "react";
import { useInitDataStore } from "../../../../store/initData.store";

import { shallow } from "zustand/shallow";
import AppItemsWithPagination from "../../../../common/UI/AppItemsWithPagination";
import CardEnabledCampus from "../../components/cardEnabledCampus";

import style from "./EnabledCampusStudent.module.css";

import { GetEnabledCampuSWithPaginationService } from "../../services/EnabledCampus/getEnabledCampuSWithPagination.service";

const getEnabledCampuSWithPaginationService = new GetEnabledCampuSWithPaginationService();

export default function EnabledCampusStudentPage() {
  const dataInitDataStore = useInitDataStore(
    (state) => ({
      userRoles: state.initData.userRoles.map(item => ({ value: String(item.roleId), label: item.role.name })),
      rolSelected: state.rolSelected,
      setRolSelected: state.setRolSelected,
      userCampus: state.initData?.userCampus ?? [].map((item: any) => ({
        campusId: String(item.campusId),
        campusName: String(item.campusName)
      })),
    }),
    shallow
  );
  console.log(dataInitDataStore.rolSelected);

  const [isReloadData, setIsReloadData] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);


  useEffect(() => {
    setIsReloadData(!isReloadData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={style.containerQr}>
            <AppItemsWithPagination
        title="Administración Campus Estudiantes"
        service={getEnabledCampuSWithPaginationService}
        refreshKey={refreshKey}
      >
        {(itemData) => (
          <div className={style.container__componente_body_Card} key={itemData.campusId}>
            <CardEnabledCampus
              key={itemData.campusId}
              campusId={String(itemData.campusId)}
              enabled={itemData.enabled}
              title={itemData.campusName}
              titlePosition='start'
              backgroundColor='#042F53'
              titleColor='#FFF'
              showCheckbox={true}
              refreshKey={setRefreshKey}
            />
          </div>
        )}
      </AppItemsWithPagination>
    </div>
  );
}
