import { useCallback, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { toast } from 'react-toastify';
import { shallow } from "zustand/shallow";
import * as yup from "yup";


import { InputText } from "../../../../common/UI/Inputs";
import AppGetIcon from "../../../../common/UI/AppGetIcon";
import DataTable from "../../../../common/UI/DataTable/dataTable";
import Modal from "../../../../common/UI/Modal";
import AppInputSearch from "../../../../common/UI/Inputs/appInputSearch";

import { useHeaderStore } from "../../../../store/header.store";

import usePermission from "../../../../common/hooks/usePermission";

import { UserDataDto } from "../../dtos/users.dto";

import style from "./Users.module.css";
import styleButton from "../../../../common/theme/styles/button.module.css";
import styleForm from "../../../../common/theme/styles/form.module.css";
import styleDataTable from '../../../../common/UI/DataTable/dataTable.module.css';

import { GetCampusWithPaginationService } from "../../../booking/services/getCampusWithPagination.service";
import { GetUsersWithPaginationService } from "../../services/admin/getUsersWithPagination.service";
import { GetRolesService } from "../../services/admin/permissionRoles/getRoles.service";
import { CreateOrUpdatedUserService } from "../../services/admin/createOrUpdatedUser.service";
import { CreateOrUpdatedUserCampusService, userCampusData } from "../../services/admin/createOrUpdateUserCampus.service";
import { DeleteUserCampusService } from "../../services/admin/deleteUserCampus.service";


const createOrUpdatedUserService = new CreateOrUpdatedUserService();
const createOrUpdatedUserCampusService = new CreateOrUpdatedUserCampusService();
const getUsersWithPaginationService = new GetUsersWithPaginationService();
const deleteUserCampusService = new DeleteUserCampusService();

export default function Users() {
  const canCreateUser = usePermission('booking.users', 'create');
  const canEditUser = usePermission('booking.users', 'edit');

  const headerStore = useHeaderStore(
    (state) => ({
      setModule: state.setModule,
      setModuleItem: state.setModuleItem,
    }),
    shallow
  );
  const [loading, setLoading] = useState(false);
  const [isReloadData, setIsReloadData] = useState(false);
  const [rolesData, setRolesData] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isHoveredEdit, setIsHoveredEdit] = useState<number | null>(null);
  const [dataCreateUser, setDataCreateUser] = useState<UserDataDto>({
    isUserCreate: false,
    user: {
      id: null,
      username: "",
      active: true,
    },
    roles: [],
    campus: [],
  });
  const [initialCampus, setInitialCampus] = useState<any[]>([]);
  const columns = [
    {
      header: "USUARIO",
      field: "email",
    },
    {
      header: "ROLES",
      field: "roles",
      render: (row: any, index: any) => {
        return <div>{row.roles}</div>;
      },
    },
    {
      header: "CAMPUS",
      field: "campuses",
      render: (row: any, index: any) => {
        const campusName = row.campuses ? row.campuses.map((campus: any) => campus.campusName).join(", ") : [];
        return <div>{campusName}</div>;
      },
    },
    {
      header: "ESTADO",
      field: "active",
      render: (row: any, index: any) => {
        return <div>{row.active ? "Activo" : "Desactivado"}</div>;
      },
    },
    {
      header: "OPCIONES",
      field: "",
      render: (row: any, index: any) => {

        return (
          canEditUser ?
            <button
              className={styleButton.Buttom_option}
              onClick={() => handleEditUser(row)}
              onMouseEnter={() => setIsHoveredEdit(row.id)}
              onMouseLeave={() => setIsHoveredEdit(null)}
            >
              <AppGetIcon
                name="edit"
                classIcon={styleDataTable.sizeIcon}
                strokeColor="#962330"
                hoverColor={isHoveredEdit === row.id ? "#FFF" : undefined}
              />
              Editar
            </button>
            : <></>
        );
      },
    },
  ];

  const LoginSchema = yup.object().shape({
    username: yup.string().required("Username es requerido"),
    roleIds: yup
      .array()
      .min(1, "Debes seleccionar al menos una opción")
      .nullable(),
    campus: yup
      .array()
      .nullable(),
  });

  const handleCloseModal = () => {
    setIsOpen(false);
    setDataCreateUser({
      isUserCreate: false,
      user: {
        id: null,
        username: "",
        active: true,
      },
      roles: [],
      campus: []
    });
    setInitialCampus([]);
  };

  const addButton = () => {
    setIsOpen(true);
  };

  const getDataRoles = async () => {
    let response = await GetRolesService();
    setRolesData(
      response.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    );
  };

  const handleEditUser = async (row: any) => {
    let rolesArray = row.roles?.split(", ") ?? [];
    let selectedRoles = rolesData.filter((role) => {
      return rolesArray.includes(role.label) ?? [];
    });
    setInitialCampus(row.campuses);
    setDataCreateUser({
      isUserCreate: false,
      user: {
        id: row.id,
        username: row.email.split("@")[0],
        active: row.active,
      },
      roles: selectedRoles,
      campus: row.campuses.map((item: any) => ({ label: item.campusId, value: item.campusId }))
    });
    setIsOpen(true);
  };

  const handleSubmit = async (values: any, actions: any) => {
    if (actions.errors && Object.keys(actions.errors).length !== 0) {
      actions.setSubmitting(false);
      return;
    };

    let currentRoles = values.roleIds;
    let selectedRoles = rolesData.filter((role) =>
      currentRoles.some((currentRole: any) => currentRole.label === role.label)
    );
    setLoading(true);
    let dataCreated: any = {
      isUserCreate: false,
      user: {
        id: dataCreateUser.user.id ?? null,
        username: values.username.replace(/\s/g, "") + "@unab.cl",
        active: values.isActive ? 1 : 0,
      },
      roles: selectedRoles.map((role: any) => Number(role.value)),
    };
    const response = await createOrUpdatedUserService.run(dataCreated);
    const userId = response.data?.userId || dataCreateUser.user.id;
    const selectedCampus = values.campus || [];
    // Eliminar campus que estaban inicialmente pero no están seleccionados actualmente
    const campusToDelete = initialCampus.filter(
      initial => !selectedCampus.some((selected: any) => selected.value === initial.campusId)
    );
    try {
      await Promise.all(campusToDelete.map(async (campus: any) => {
        await deleteUserCampusService.run(campus.userCampusId);
      }));
    } catch (error) {
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <AppGetIcon name="xCircleFill" />
          Ocurrió un error al intentar eliminar el campus. Por favor, intenta de nuevo.
        </div>,
        { icon: false }
      );
    };


    // Agregar nuevos campus seleccionados que no estaban inicialmente
    const campusToAdd = selectedCampus.filter(
      (selected: any) => !initialCampus.some(initial => initial.campusId === selected.value)
    );
    try {
      await Promise.all(campusToAdd.map(async (campus: any) => {
        let dataCampusCreated: userCampusData = {
          id: null,  // Asumimos que es un nuevo campus
          userId: userId,
          campusId: campus.value,
          campusName: campus.label // o el nombre del campus si está disponible en los datos
        };
        await createOrUpdatedUserCampusService.run(dataCampusCreated);
      }));
    } catch (error) {
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <AppGetIcon name="xCircleFill" />
          Ocurrió un error al intentar asociar el campus. Por favor, intenta de nuevo.
        </div>,
        { icon: false }
      );
    };
    setDataCreateUser({
      isUserCreate: false,
      user: {
        id: null,
        username: "",
        active: true,
      },
      roles: [],
      campus: []
    });
    setIsReloadData(!isReloadData);
    setInitialCampus([]);
    setLoading(false);
    setIsOpen(false);
    actions.setSubmitting(false);
  };

  const serviceCallCampus = useCallback(async (value: string) => {
    const response: any = await GetCampusWithPaginationService({
      search: value,
      perPage: 100
    });
    return response.data.map((item: any) => ({
      label: item.campusId,
      value: item.campusId
    }));
  }, []);

  useEffect(() => {
    headerStore.setModule("Administración", "");
    headerStore.setModuleItem("Usuarios", "/admin/home");
    getDataRoles();
  }, [headerStore]);

  return (
    <div className={style.containerQr}>
      <DataTable
        title="Lista de usuarios"
        columns={columns}
        service={getUsersWithPaginationService}
        reloadData={isReloadData}
        addButton={canCreateUser ? addButton : undefined}
        excelButton={{
          enabled: true,
          nameFile: `Lista de usuarios`,
          getExcelData: (data) => {
            return data.map((row) => ({
              NOMBRE: `${row.firstnames ?? ""} ${row.lastnames ?? ""}`,
              EMAIL: row.email,
              ROLES: row.roles,
              "SEDE/CAMPUS": row.faculties ? row.faculties.map((faculty: any) => faculty.campusName).join(", ") : '',
              // "ESCUELA/FACULTAD": row.faculties ? row.faculties.map((faculty: any) => faculty.facultyName).join(", ") : '',
              ESTADO: row.active ? "Activado" : "Desactivado",
            }));
          },
        }}
      >{''}</DataTable>
      <Modal
        title={
          dataCreateUser.user.username ? "Editar usuario" : `Crear usuario`
        }
        isOpen={isOpen}
        onClose={handleCloseModal}
        size={{ width: "80%", maxWidth: "1200px", height: "" }}
        loadingModal={loading}
        footerContent={
          <div className={style.containerButtoms}>
            <button
              className={
                styleForm.buttomFooter + " " + styleForm.buttom_cancelar
              }
              onClick={handleCloseModal}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className={styleForm.buttomFooter}
              form="activityForm"
              disabled={loading}
            >
              Guardar
            </button>
          </div>
        }
      >
        <Formik
          initialValues={{
            username: dataCreateUser.user.username ?? "",
            roleIds: dataCreateUser.roles ?? [],
            campus: dataCreateUser.campus ?? [],
            isActive: dataCreateUser.user.active ? true : false,
          }}
          validationSchema={LoginSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, values, setFieldValue }) => {
            return (
              <Form
                id="activityForm"
                className="flex-row"
                style={{ width: "100%" }}
              >
                <InputText
                  type="text"
                  classNameInput={styleForm.input__input}
                  name="username"
                  placeholder="Usuario del dominio unab.cl"
                  TextLabel="Usuario"
                />
                <AppInputSearch
                  classNameInput={styleForm.input__input}
                  data={rolesData}
                  onSelect={(selected) => {
                    setFieldValue("roleIds", selected);
                  }}
                  selected={values.roleIds}
                  name="roleIds"
                  textLabel="Selecione rol"
                  multiple={true}
                />
                {values.roleIds && values.roleIds.some((role: any) => (role.value === 51 || role.value === 55 )) &&
                  <AppInputSearch
                    classNameInput={styleForm.input__input}
                    serviceCall={serviceCallCampus}
                    onSelect={selected => {
                      setFieldValue("roleIds", selected);

                    }}
                    selected={values.campus}
                    name="campus"
                    textLabel="Selecione campus"

                    multiple={true}
                  />
                }

                <div>
                  <label className={style.title}>Activo</label>
                  <Field
                    name="isActive"
                    render={({ field }: any) => (
                      <>
                        <input
                          {...field}
                          id="isActive"
                          type="checkbox"
                          checked={field.value}
                        />
                      </>
                    )}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}
