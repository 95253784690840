
import { useCallback, useEffect, useState } from "react";
import { Field, Formik, Form, FieldProps } from "formik";
import * as yup from "yup";
import { shallow } from "zustand/shallow";
import useFormattedDate from "../../../../common/hooks/useFormattedDate";
import { useUserStore } from "../../../../store/user.store";

import Modal from "../../../../common/UI/Modal";
import DateInput from "../../../../common/UI/Inputs/InputDate";
import ConatinerFilter from "../../../../common/UI/DataTable/filterModal/containerFilter";
import AppButton from "../../../../common/UI/Buttons/AppButton";
import DataTable from "../../../../common/UI/DataTable/dataTable";
import { IOption } from '../../../../common/UI/Nabvar/DropdownSelect';
import { InputTextArea } from "../../../../common/UI/Inputs";
import styleDataTable from "../../../../common/UI/DataTable/dataTable.module.css";
import AppInputSearchFilter from "../../../../common/UI/Inputs/appInputSearchFilter";
import InputSearch from "../../../../common/UI/DataTable/inputSearch";

import style from "./DashboardScreenView.module.css";
import styleButton from "../../../../common/theme/styles/button.module.css";
import styleForm from "../../../../common/theme/styles/form.module.css";

import { GetRotationDashboardScreenViewWithPaginationService } from "../../services/DashboardScreenView/getRotationDashboardScreenViewWithPagination.service";
import { CreateOrUpdateDashboardScreendViewService, IClass } from "../../services/DashboardScreenView/createOrUpdateDashboardScreendView.service";
import { DataSelectDto } from "../../../../common/dtos/general.dto";
import { GetCampusWithPaginationService } from "../../services/getCampusWithPagination.service";
import { GetBuildingWithPaginationService } from "../../services/getBuildingWithPagination.service";
import { GetReasonsWithPaginationService } from "../../services/RotationScreenView/getReasonsWithPagination.service";
import { GetRoomsService } from "../../services/getRooms.service";
import { GetUsersWithPaginationService } from "../../services/getUsersWithPagination.service";
import { useInitDataStore } from "../../../../store/initData.store";
import { GetHoursBlockWithPaginationService } from "../../services/getHoursBlockWithPagination.service";
import InputSearchFilter from "../../../../common/UI/Inputs/InputSearchFilter";
import { GetTermCodeWithPaginationService } from "../../services/DashboardScreenView/getTermCodeWithPagination.service";
import AppGetIcon from "../../../../common/UI/AppGetIcon";
import { CreateOrUpdateGetRoomsMultipleService } from "../../services/DashboardScreenView/createOrUpdateGetRoomsMultiple.service";
import AppInputSearch from "../../../../common/UI/Inputs/appInputSearch";
import DateInputFormik from "../../../../common/UI/Inputs/InputDateFormik";


const createOrUpdateGetRoomsMultipleService = new CreateOrUpdateGetRoomsMultipleService();
const getRotationScreenViewWithPaginationService = new GetRotationDashboardScreenViewWithPaginationService();
const createOrUpdateDashboardScreendViewService = new CreateOrUpdateDashboardScreendViewService();
const getUsersWithPaginationService = new GetUsersWithPaginationService();

export default function GeneralReservationLimitPage() {
  const hFormatDate = useFormattedDate();
  const dataUserStore = useUserStore(
    state => ({
      userId: state.dataUser
    }),
    shallow
  );
  const today = new Date();
  const dataInitDataStore = useInitDataStore(
    (state) => ({
      userRoles: state.initData.userRoles.map(item => ({ value: String(item.roleId), label: item.role.name })),
      rolSelected: state.rolSelected,
      setRolSelected: state.setRolSelected,
      userCampus: state.initData?.userCampus ?? [].map((item: IOption) => ({
        campusId: String(item.facultyCode ?? ''),
        campusName: String(item.facultyName ?? '')
      })),
    }),
    shallow
  );
  const [isReloadData, setIsReloadData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [initialData, setInitialData] = useState<IClass | null>(null);
  const [buildingIds, setBuildingIds] = useState<any>('');
  const [roomCodes, setRoomCodes] = useState<any>('');
  const [termCodes, setTermCodes] = useState<any>('');
  const [dateFilter, setDateFilter] = useState<any>(today);
  const [isOpenPreview, setIsOpenPreview] = useState<any>(null);

  const [campus, setCampus] = useState<DataSelectDto>({
    label: '',
    value: '',
  });
  const [buildingId, setBuildingId] = useState<DataSelectDto>({
    label: "",
    value: "",
  });
  const [roomId, setRoomId] = useState<DataSelectDto>({
    label: "",
    value: "",
  });

  const [campusFilter, setCampusFilter] = useState<DataSelectDto>({
    label: String(dataInitDataStore?.userCampus[0]?.campusName ?? ''),
    value: String(dataInitDataStore?.userCampus[0]?.campusId ?? '')
  });

  const [buildingIdFilter, setBuildingIdFilter] = useState<DataSelectDto>({
    label: "",
    value: "",
  });
  const [replacementTeacher, setReplacementTeacher] = useState<DataSelectDto>({
    label: "",
    value: "",
  });
  const [reason, setReason] = useState<DataSelectDto>({
    label: "",
    value: "",
  });

  const typeResourceData: DataSelectDto[] = [
    {
      label: "Todos",
      value: "",
    },
    {
      label: "Recintos Academicos",
      value: "1",
    },
    {
      label: "Extracurricular",
      value: "2",
    },
  ];

  const [typeResourceId, setTypeResourceId] = useState<DataSelectDto>({
    label: "Todos",
    value: "",
  });
  const [hoursBlockStart, setHoursBlockStart] = useState({ label: '', value: '' });
  const [hoursBlockEnd, setHoursBlockEnd] = useState({ label: '', value: '' });
  const [searchNrc, setSearchNrc] = useState<string>('');
  const [searchRoom, setSearchRoom] = useState({
    label: '',
    value: ''
  });
  const [warningMessage, setWarningMessage] = useState("");
  const [termcCodeIdVal, setTermCodeIdVal] = useState<DataSelectDto>({
    label: '',
    value: ''
  })
  const serviceUsersAll = async (value: any) => {
    let params: any = {
      search: value,
      uabEmails: 1,
      roleId: 12
    };
    let response: any = await getUsersWithPaginationService.run(params);
    return response.data.map((item: any) => ({
      label: item.lastnames + ' ' + item.firstnames,
      value: item.id
    }));
  };
  const serviceCallTermCode = useCallback(async (value: string) => {
    const response: any = await GetTermCodeWithPaginationService({
      search: value,
      perPage: 1000
    });
    return response.data.map((item: any) => ({ label: item.SYBTERM_TERM_DESC, value: item.sybterm_term_code }));
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);
  const serviceCallCampus = useCallback(async (value: string) => {
    const response: any = await GetCampusWithPaginationService({
      search: value,
      perPage: 1000
    });
    return response.data.map((item: any) => ({
      label: item.campusId,
      value: item.campusId,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);
  const serviceCallNewCampus = useCallback(async (value: string) => {
    const response: any = dataInitDataStore?.userCampus;
    return response.map((item: any) => ({
      label: item.campusId,
      value: item.campusId,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const serviceCallBuilding = useCallback(
    async (dataSearch: any) => {
      let campusSelected = campusFilter.value || campus.value;
      const response: any = await GetBuildingWithPaginationService({
        search: dataSearch,
        campusId: campusSelected,
        perPage: 1000,
        sort: 'id',
        order: 'DESC'
      });
      return response.data.map((item: any) => ({
        label: item.buildingId,
        value: item.buildingId,
      }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [campusFilter.value, campus.value]
  );
  const serviceCallRoomsFilter = useCallback(
    async (dataSearch: any) => {
      let buildingIdSelected = buildingIds.value;
      let buildingIdsArray = buildingIdSelected.split(',');

      let dataCreated: any = {
        search: dataSearch,
        buildingIds: buildingIdsArray,
      };

      try {
        const response = await createOrUpdateGetRoomsMultipleService.run(dataCreated);
        return response.data.map((item: any) => ({
          label: item.roomCode,
          value: item.roomCode,
        }));
      } catch (error) {
        console.error("Error guardando el recurso", error);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [campus.value, buildingIds.value,]
  );
  const serviceCallRooms = useCallback(
    async (dataSearch: any) => {
      let campusSelected = campus.value;
      let buildingIdSelected = buildingId.value;
      const response = await GetRoomsService({
        search: dataSearch,
        campusId: campusSelected,
        buildingId: buildingIdSelected,
        perPage: 100,
        sort: 'id',
        order: 'DESC'
      });

      return response.data.map((item: any) => ({
        label: item.roomCode,
        value: item.roomCode,
      }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [campus.value, buildingId.value,]
  );
  const serviceCallReasons = useCallback(async (value: string) => {
    const response: any = await GetReasonsWithPaginationService({
      search: value,
      perPage: 100
    });
    return response.data.map((item: any) => ({
      label: item.name,
      value: item.id,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const serviceCallHoursBlock = async (dataSearch: any) => {
    const response: any = await GetHoursBlockWithPaginationService(dataSearch);
    const filteredData = response.data
      .filter((item: any) => item.type === 0)
      .map((item: any) => ({
        ...item,
        label: item.hourBlock,
        value: item.id
      }));
    return filteredData;
  };

  const serviceCallHoursBlockEnd = async (dataSearch: any) => {
    const response: any = await GetHoursBlockWithPaginationService(dataSearch);
    const filteredData = response.data
      .filter((item: any) => item.type === 1)
      .map((item: any) => ({
        ...item,
        label: item.hourBlock,
        value: item.id
      }));
    return filteredData;
  };
  const columns = [
    {
      header: "PROFESOR",
      field: "teacherName",
      render: (row: any) => {
        const teacherNames = typeof row.teacherName === 'string' ? row.teacherName.split(',').filter(Boolean) : [];
        if (teacherNames.length === 0) return '';
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap', width: '200px' }}>
            {teacherNames.map((name: string, index: number) => (
              <span key={index} className={styleDataTable.ships}>
                {name.trim()}
              </span>
            ))}
          </div>
        );
      },
    },
    {
      header: "TIPO",
      field: "type",
      render: (row: any) => {
        if (row.type === 1) {
          return 'Curricular';
        } else if (row.type === 2) {
          return 'Extracurricular';
        }
        return row.type;
      },
    },
    {
      header: "EVENTO",
      field: "descriptionCourse",
    },
    {
      header: "FECHA",
      field: "dateSince",
      render: (row: any) => {
        return hFormatDate(row.dateSince, "yyyy-MM-dd");
      },
    },



    {
      header: "BLOQUES HORARIOS",
      field: "",
      render: (row: any) => {
        const startTimes = row.startTime ? row.startTime.split(',') : [];
        const endTimes = row.endTime ? row.endTime.split(',') : [];

        const bloquesHorarios = startTimes.map((start: any, index: any) => {
          const end = endTimes[index] ? endTimes[index].trim() : '';
          const formattedStart = start.trim().split(':').slice(0, 2).join(':'); // hh:mm
          const formattedEnd = end.split(':').slice(0, 2).join(':'); // hh:mm

          return (
            <div
              key={index}
              className={styleDataTable.ships}
            >
              {`${formattedStart} - ${formattedEnd}`}
            </div>
          );
        });

        // Devuelve un contenedor con los bloques como elementos flexibles
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {bloquesHorarios}
          </div>
        );
      },
    },
    {
      header: "CAMPUS",
      field: "campusId",
    },
    {
      header: "RECURSOS",
      field: "roomCode",
      render: (row: any) => {
        const roomResources = typeof row.roomCode === 'string' ? row.roomCode.split(',').filter(Boolean) : [];

        if (roomResources.length === 0) return '';

        return (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {roomResources.map((resource: string, index: number) => (
              <span key={index} className={styleDataTable.ships}>
                {resource.trim()}
              </span>
            ))}
          </div>
        );
      },
    },
    {
      header: "NRC",
      field: "NRC",
    },
    {
      header: "ESTADO",
      field: "status",
      render: (row: any) => {
        const getStatus = () => {
          if (row.reasonId === 4) return "Salida terreno";
          if (row.reasonId === 5) return "Docente atrasado";
          if (row.reasonId === 6) return "Cambio a modalidad online";
          if (row.reasonId === 7) return "Cambio de sala";
          if (row.reasonId === 8) return "Iniciada";
          if (row.reasonId === 9) return "Finalizada";
          if (row.reasonId === 10) return "Cancelada";
          if (row.reasonId === 11) return "Cambio docente";
          if (row.finished === 0) return "Iniciada";
          if (row.finished === 1) return "Finalizada";
          if (row.finished === 2) return "Reabierta";
          if (row.timeStatus === 1) return "No iniciada";
          if (row.timeStatus === 2) return "En curso";
          if (row.timeStatus === 3) return "Finalizada";
          return '';
        };

        const getStatusClass = () => {
          if (row.reasonId === 4) return style.estadoSalidaTerreno;
          if (row.reasonId === 5) return style.estadoDocenteAtrasado;
          if (row.reasonId === 6) return style.estadoCambioModalidad;
          if (row.reasonId === 7) return style.estadoCambioSala;
          if (row.reasonId === 8) return style.estadoIniciada;
          if (row.reasonId === 9) return style.estadoFinalizada;
          if (row.reasonId === 10) return style.cancelado;
          if (row.reasonId === 11) return style.cambioDocente;
          if (row.finished === 0) return style.estadoIniciada;
          if (row.finished === 1) return style.estadoFinalizada;
          if (row.finished === 2) return style.estadoReabierta;
          if (row.timeStatus === 1) return style.noIniciado;
          if (row.timeStatus === 2) return style.enCurso;
          if (row.timeStatus === 3) return style.finalizado;
          return '';
        };
        const getIconName = () => {
          if (row.reasonId === 4) return "images";
          if (row.reasonId === 5) return "personCard";
          if (row.reasonId === 6) return "laptop";
          if (row.reasonId === 7) return "buildingGear";
          if (row.reasonId === 8) return "circleCheck";
          if (row.reasonId === 9) return "clipBoardCheck";
          if (row.reasonId === 10) return "xCircle";
          if (row.reasonId === 11) return "personUp";
          if (row.finished === 0) return "circleCheck";
          if (row.finished === 1) return "clipBoardCheck";
          if (row.finished === 2) return "easel";
          if (row.timeStatus === 1) return "clockV2";
          if (row.timeStatus === 2) return "infoCircle";
          if (row.timeStatus === 3) return "clipBoardCheck";
          return '';
        };
        return (
          <div className={style.iconsTable}>
            <div className={getStatusClass()}>

              {getStatus()}
              <AppGetIcon style={{ minWidth: '26px' }}
                name={getIconName()}
                classIcon={styleDataTable.sizeIcon}
                hoverColor={"#FFF"}
                strokeColor="#FFF"
              />
            </div>
          </div>
        );
      },
    },
    {
      header: "DOCENTE ASISTE",
      field: "showAdvertising",
      render: (row: any) => {
        return (row.finished || row.attend === 1) ? (
          <span style={{ color: "#00FF00", textShadow: "0 0 2px #00FF00, 0 0 5px #00FF00" }}>✔️</span>
        ) : (
          <span style={{ color: "red", fontWeight: "bold", fontSize: "1em" }}>❌</span>
        );
      },
    },
    {
      header: "OPCIONES",
      field: "",
      render: (row: any) => {
        const userCampusIds = dataInitDataStore?.userCampus.map((campus) => campus.campusId);
        const canEdit = userCampusIds.includes(row.campusId);
        return (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', gap: '5px' }}>
            {(canEdit || dataInitDataStore?.rolSelected === 1) && (
              <AppButton
                onClick={() => handleEditUser(row)}
                iconName="edit"
                buttonText="Editar"
                variant="primary"
              />
            )}
            <AppButton
              onClick={() => handleOpenPreview(row)}
              iconName="eye"
              buttonText="Ver"
              variant="primary"
            />
          </div>
        );
      },
    },

  ];

  const reservationLimitSchema = yup.object().shape({
    replacementTeacherId: (String(reason.value) === "11") ? yup.string().required("El campo profesor es requerido") : yup.string().notRequired(),
    remplacementCampusId: (String(reason.value) === "7") ? yup.string().required("El campo campus es requerido") : yup.string().notRequired(),
    remplacementBuildingId: (String(reason.value) === "7") ? yup.string().required("El campo edificio es requerido") : yup.string().notRequired(),
    remplacementRoomId: (String(reason.value) === "7") ? yup.string().required("El campo salón es requerido") : yup.string().notRequired(),

  });
  // Lógica de validación
  const validateFilter = () => {
    if (hoursBlockStart.label && !hoursBlockEnd.label) {
      setWarningMessage("El bloque fin es obligatorio si seleccionas un bloque de inicio.");
      return false;
    }
    if (!hoursBlockStart.label && hoursBlockEnd.label) {
      setWarningMessage("El bloque inicio es obligatorio si seleccionas un bloque de fin.");
      return false;
    }
    setWarningMessage(""); 
    return true;
  };
  // Manejador del filtro
  const handleFilter = () => {
    if (validateFilter()) {
      setIsReloadData(!isReloadData); 
    }
  };
  const handleSubmit = async (values: IClass, actions: any) => {
    if (actions.errors && Object.keys(actions.errors).length !== 0) {
      actions.setSubmitting(false);
      return;
    };
    let dataCreated: any = {
      id: values.updatedClassId ?? null,
      creatorUserId: dataUserStore.userId.id,
      reasonId: reason.value === '' ? null : reason.value,
      attend: values?.attend ?? null,
      replacementTeacherId: replacementTeacher?.value === 'addString' ? null : values?.replacementTeacherId,
      teacherNameString: replacementTeacher?.value === 'addString' ? replacementTeacher?.label : null,
      timeLate: hFormatDate(values?.timeLate, 'HH:mm') ?? '',
      minutesLate: values?.minutesLate ?? '',
      timeEarly: hFormatDate(values?.timeEarly, 'HH:mm') ?? '',
      minutesEarly: values?.minutesEarly ?? null,
      editorUserId: values?.editorUserId ?? values?.updatedClassId ? dataUserStore.userId.id : null,
      NRC: values?.NRC ?? null,
      roomId: values?.type === 1 ? values?.roomId : null,
      buildingId: values?.type === 1 ? values?.buildingId : null,
      campusId: values?.type === 1 ? values?.campusId : null,
      termCode: values?.type === 1 ? values?.termCode : null,
      dateSince: values?.type === 1 ? values.dateSince : null,
      dateUntil: values?.type === 1 ? values.dateUntil : null,
      startTime:  values?.startTime ?? null,
      endTime: values?.endTime ?? null,
      remplacementCampusId: Number(reason.value) === 7 ? values?.remplacementCampusId : null,
      remplacementBuildingId: Number(reason.value) === 7 ? values?.remplacementBuildingId : null,
      remplacementRoomId: Number(reason.value) === 7 ? values?.remplacementRoomId : null,
      observations: values?.observations ?? null,
    };
    try {
      await createOrUpdateDashboardScreendViewService.run(dataCreated);

      setInitialData(null);
      setIsReloadData(!isReloadData);
      setIsOpen(false);
    } catch (error) {
      console.error("Error guardando el recurso", error);
    }
  };

  const handleCloseModal = () => {
    setInitialData(null);  // Limpia los datos iniciales
    setIsOpen(false);
  };
  const handleEditUser = async (row: IClass) => {
    const formattedDateSince = hFormatDate(row?.dateSince, "dd/MM/yyyy");
    const formattedDateUntil = hFormatDate(row?.dateUntil, "dd/MM/yyyy");
    const currentDate = new Date();
    const createDateFromTime = (time: string) => {
      if (!time) return null;
      const [hours, minutes] = time.split(':').map(Number);
      const date = new Date(currentDate);
      date.setHours(hours);
      date.setMinutes(minutes);
      date.setSeconds(0);
      return date;
    };
  
    const lateDate = createDateFromTime(row?.timeLate);
    const earlyDate = createDateFromTime(row?.timeEarly);
  
    setReason({
      label: row?.reasonName,
      value: String(row?.reasonId) ?? ''
    });
    
    setCampus({ label: row?.remplacementCampusId, value: row?.remplacementCampusId });
    setBuildingId({ label: row?.remplacementBuildingId, value: row?.remplacementBuildingId });
    setRoomId({ label: row?.remplacementRoomId, value: row?.remplacementRoomId });
  
    setReplacementTeacher({
      label: row?.replacementTeacherName ? row?.replacementTeacherName : row?.teacherNameString ? row?.teacherNameString : '',
      value: row?.replacementTeacherId ? row?.replacementTeacherId : row?.teacherNameString ? 'addString' : ''
    });
  
    setInitialData({
      id: row.id ?? null,
      reasonId: row?.reasonId ?? null,
      creatorUserId: row?.creatorUserId ?? null,
      attend: row?.attend ?? null,
      finished: row?.finished ?? null,
      replacementTeacherId: row?.replacementTeacherId ? row?.replacementTeacherId : row?.teacherNameString ? 'addString' : '',
      replacementTeacherName: row?.replacementTeacherName ?? '',
      teacherNameString: row?.teacherNameString ?? '',
      timeLate: lateDate, // Usar el objeto Date
      timeEarly: earlyDate, // Usar el objeto Date
      minutesLate: row?.minutesLate ?? '',
      minutesEarly: row?.minutesEarly ?? '',
      editorUserId: row?.editorUserId ?? null,
      NRC: row?.NRC ?? null,
      roomId: row?.roomCode ?? null,
      buildingId: row?.buildingId ?? null,
      campusId: row?.campusId ?? null,
      termCode: row?.termCode ?? null,
      dateSince: formattedDateSince ?? null,
      dateUntil: formattedDateUntil ?? null,
      startTime: row?.startTime ?? null,
      endTime: row?.endTime ?? null,
      remplacementCampusId: row?.remplacementCampusId ?? null,
      remplacementBuildingId: row?.remplacementBuildingId ?? null,
      remplacementRoomId: row?.remplacementRoomId ?? null,
      observations: row?.observations ?? null,
      type: row?.type ?? null,
      updatedClassId: row.updatedClassId ?? null,
      reasonName: row?.reasonName ?? null,
    });
  
    setIsOpen(true);
  };
  const handleFilterButtonClick = () => {
    setShowFilterModal(true);
  };
  const handleFilterModalClose = () => {
    setShowFilterModal(false);
  };
  const [filterKey, setFilterKey] = useState(0); 
  const handleDeleteFilter = () => {
    setDateFilter(null);
    setCampusFilter({
      label: '',
      value: ''
    });
    setBuildingIdFilter({
      label: '',
      value: ''
    });
    setTermCodeIdVal({
      label: '',
      value: ''
    });
    setBuildingIds({
      label: '',
      value: ''
    });
    setTypeResourceId({
      label: '',
      value: ''
    });
    setHoursBlockStart({
      label: '',
      value: ''
    });
    setHoursBlockEnd({
      label: '',
      value: ''
    });
    setTermCodeIdVal({
      label: '',
      value: ''
    });
    setSearchNrc('');
    setSearchRoom({
      label: '',
      value: ''
    });

    setFilterKey((prevKey) => prevKey + 1);
  };

  const handleOpenPreview = (dataFile: any) => {
    setIsOpenPreview(dataFile); 
  };
  const handleCloseModalPreview = () => {
    setInitialData(null);  
    setIsOpenPreview(null);
  };
  useEffect(() => {
    const data = Array.isArray(buildingIdFilter) ? buildingIdFilter : [];
    const dataRoomCode = Array.isArray(searchRoom) ? searchRoom : [];
    const dataTermCode = Array.isArray(termcCodeIdVal) ? termcCodeIdVal : [];

    let databuildingIds = data.length ? data.map((item) => item.value).join(",") : "";
    let searchRooms = dataRoomCode.length ? dataRoomCode.map((item) => item.value).join(",") : "";
    let termCodes = dataTermCode.length ? dataTermCode.map((item) => item.value).join(",") : "";

    setBuildingIds({ label: databuildingIds, value: databuildingIds });
    setRoomCodes({ label: searchRooms, value: searchRooms });
    setTermCodes({ label: termCodes, value: searchRooms });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter, campusFilter.value, buildingIdFilter, searchRoom, termcCodeIdVal.value]);


  return (
    <div className={style.containerQr}>
      <DataTable
        title="Programación Diaria"
        columns={columns}
        service={getRotationScreenViewWithPaginationService}
        params={{
          campusId: campusFilter.value,
          buildingId: buildingIds.value ?? '',  // Asigna el valor de `buildingIds` o "" si es `null`
          roomCode: roomCodes.value ?? '',
          date: dateFilter ? hFormatDate(dateFilter, "yyyy-MM-dd") : "",
          type: typeResourceId.value?  Number(typeResourceId.value) : null,
          startTime: hoursBlockStart.label,
          endTime: hoursBlockEnd.label,
          NRC: searchNrc,
          termCode: termCodes.label
        }}
        reloadData={isReloadData}
        isEncryp={true}
        alertText="No se encontró información, recuerda realizar el filtro para poder ver la información de la tabla."
        searchText="Buscar Evento"
        
      >
        <ConatinerFilter
          nameIcon="filter"
          onClick={() => handleFilterButtonClick()}
          isOpen={showFilterModal}
          onClose={handleFilterModalClose}
          handleDeleteFilter={handleDeleteFilter}
          handleFilter={handleFilter} // Usa la nueva función de manejo de filtro
        >
          <div
            className={style.container__select}
            style={{ minWidth: "280px", paddingBottom: " 8px" }}
          >
            <DateInput
              key={filterKey} // Utiliza la clave para forzar la actualización del componente
              name="fecha"
              textLabel="Selecionar fecha"
              classNameInput={styleForm.input__input}
              initialValue={dateFilter}
              dateFormat="dd-MM-yyyy"
              onChange={(dateSelected: any) => {
                setDateFilter(dateSelected);
              }}
            clearable={false} // No mostrará la X para limpiar la fecha
            />

          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "280px" }}
          >
            <InputSearch
              key={Math.random()}
              name="type"
              classNameInput={styleForm.input__input}
              textLabel="Tipo recurso"
              data={typeResourceData}
              onSelect={(selected) => {
                setTypeResourceId({
                  label: selected.label,
                  value: selected.value
                });
              }}
              selected={typeResourceId}
            />
          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "280px", paddingBottom: " 1px" }}
          >
            <InputSearch
              key={Math.random()}
              serviceCall={serviceCallHoursBlock}
              onSelect={selected =>
                setHoursBlockStart({
                  label: selected.label,
                  value: selected.value
                })
              }
              selected={hoursBlockStart}
              name="startHoursBlock"
              textLabel="Bloque inicio"
              classNameInput={styleForm.input__input}
            />

          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "280px", }}
          >

            <InputSearch
              key={Math.random()}
              serviceCall={serviceCallHoursBlockEnd}
              onSelect={setHoursBlockEnd}
              selected={hoursBlockEnd}
              name="endHoursBlock"
              textLabel="Bloque fin"
              classNameInput={styleForm.input__input}
            />

          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "280px", paddingBottom: " 10px" }}
          >

            <InputSearchFilter
              name="searchNrc"
              placeholder="Buscar por NRC"
              label="Buscar por NRC"
              value={searchNrc}  // Pasa el valor del estado como prop
              onSearchChange={(value) => setSearchNrc(value)}  // Mantiene la funcionalidad del callback
              classNameInput={styleForm.input__input}
            />

          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "280px" }}
          >
            <InputSearch
              key={Math.random()}
              name="campusId"
              classNameInput={styleForm.input__input}
              textLabel="Selecciona sede/campus"
              serviceCall={serviceCallCampus}
              onSelect={(selected) => {
                setCampusFilter(selected);
                setBuildingIdFilter({
                  label: '',
                  value: ''
                });
                setSearchRoom({
                  label: '',
                  value: ''
                });
              }}
              selected={campusFilter}  // Aquí pasamos el estado campus
            />

          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "100%" }}
          >
            <AppInputSearchFilter
              key={Math.random()}
              name="buildingId"
              classNameInput={styleForm.input__input}
              textLabel="Selecciona edificios*"
              serviceCall={serviceCallBuilding}
              onSelect={(selected) => {
                setBuildingIdFilter(selected);
                setSearchRoom({
                  label: '',
                  value: ''
                });
              }}
              selected={buildingIdFilter}
              multiple={true}
              showSelectAll={true}

            />
          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "100%", paddingBottom: " 6px" }}
          >
            <AppInputSearchFilter
              key={Math.random()}
              name="roomsId"
              classNameInput={styleForm.input__input}
              textLabel="Selecciona salones"
              serviceCall={serviceCallRoomsFilter}
              onSelect={(selected) => {
                setSearchRoom(selected);
              }}
              selected={searchRoom}
              multiple={true}
              showSelectAll={true}
            />
          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "100%", }}
          >
            <AppInputSearchFilter
              key={Math.random()}
              name="termCode"
              classNameInput={styleForm.input__input}
              textLabel="Selecciona Período"
              serviceCall={serviceCallTermCode}
              onSelect={(selected) => {
                setTermCodeIdVal(selected);
              }}
              selected={termcCodeIdVal}
              multiple={true}
              showSelectAll={true}
            />

          </div>

          {/* Renderiza el mensaje de advertencia si existe */}
          {warningMessage && (

            <div
              style={{
                backgroundColor: '#FFF3CD', // Fondo de advertencia amarillo claro
                color: '#856404', // Color del texto de advertencia (marrón)
                border: '1px solid #ffeeba', // Borde de advertencia
                padding: '10px', // Espacio interno
                borderRadius: '5px', // Bordes redondeados
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '10px', // Separación superior
                width: '100%'
              }}
            >
              <AppGetIcon name="alert" fillColor="#856404" />
              <span style={{ fontWeight: 'bold' }}>
                {warningMessage}
              </span>
            </div>



          )}

        </ConatinerFilter>
      </DataTable>


      <Modal
        title={`Ver resumen del evento`}
        isOpen={isOpenPreview}
        onClose={handleCloseModalPreview}
        size={{ width: '90%', maxWidth: '1000px', height: '' }}
        footerContent={
          <div className={styleButton.Container__buttoms_modal}>

            <button
              className={styleButton.Buttom__modal_cancel}
              onClick={handleCloseModalPreview}
            >
              Cancelar
            </button>
          </div>
        }
      >
        <div className={styleForm.form__container_row}>
          <div style={{ width: '100%' }}>
            <label className={style.informationLabel}>Nombre vento:</label>
            <span>{isOpenPreview?.descriptionCourse || 'N/A'}</span>
          </div>
          <div style={{ width: '100%' }}>
            <label className={style.informationLabel}>Fecha:</label>
            <span >{isOpenPreview?.eventDate || 'N/A'}</span>
          </div>
        </div>
        <div className={styleForm.form__container_row}>
          <div style={{ width: '100%' }}>
            <label className={style.informationLabel}>Comentario Pantalla:</label>
            <span>{isOpenPreview?.reasonName || 'N/A'}</span>
          </div>
          <div style={{ width: '100%' }}>
            <label className={style.informationLabel}>Tipo:</label>
            <span>{isOpenPreview?.type === 1 ? 'Curricular' : isOpenPreview?.type === 2 ? 'Extracurricular' : 'N/A'}</span>
          </div>
        </div>
        <div className={styleForm.form__container_row}>
          <div style={{ width: '100%' }}>
            <label className={style.informationLabel}>Profesor:</label>
            <span style={{ display: 'flex', flexWrap: 'wrap' }}>
              {isOpenPreview?.teacherName
                ? isOpenPreview.teacherName
                  .split(',')
                  .filter((teacher: any) => teacher.trim() !== '') // Filtra elementos vacíos
                  .map((teacher: any, index: any) => (
                    <div key={index} className={styleDataTable.ships}>
                      {teacher.trim()}
                    </div>
                  ))
                : 'N/A'}
            </span>

          </div>
        </div>
        <div className={styleForm.form__container_row}>
          <div style={{ width: '100%' }}>
            <label className={style.informationLabel}>Bloques Horarios:</label>
            <span style={{ display: 'flex', flexWrap: 'wrap' }}>
              {isOpenPreview?.startTime && isOpenPreview?.endTime ? (
                isOpenPreview.startTime.split(',').map((start: any, index: any) => {
                  const end = isOpenPreview.endTime.split(',')[index] || '';
                  const formattedStart = start.trim().split(':').slice(0, 2).join(':');
                  const formattedEnd = end.trim().split(':').slice(0, 2).join(':');
                  return (
                    <div key={index} className={styleDataTable.ships}>
                      {`${formattedStart} - ${formattedEnd}`}
                    </div>
                  );
                })
              ) : (
                'N/A'
              )}
            </span>
          </div>
        </div>
        <div className={styleForm.form__container_row}>
          <div style={{ width: '100%' }}>
            <label className={style.informationLabel}>Recursos:</label>
            <span style={{ display: 'flex', flexWrap: 'wrap' }}>
              {isOpenPreview?.roomCode ? (
                isOpenPreview.roomCode.split(',').map((resource: any, index: any) => (
                  <span key={index} className={styleDataTable.ships}>
                    {resource.trim()}
                  </span>
                ))
              ) : (
                'N/A'
              )}
            </span>
          </div>
        </div>
        <div className={styleForm.form__container_row}>
          <div style={{ width: '100%' }}>
            <label className={style.informationLabel}>Asistente:</label>
            <span>
              {isOpenPreview?.attend === 0 ? 'No' : isOpenPreview?.attend === 1 ? 'Sí' : 'N/A'}
            </span>

            {/* Mensaje de validación según el valor de finished */}
            <div>
              {isOpenPreview?.finished === null ? (
                <span className={styleForm.messageError}>Docente no ha iniciado clase.</span>
              ) : (
                <span className={styleForm.messageSuccess}>Docente inició clase.</span>
              )}
            </div>
          </div>

          <div style={{ width: '100%' }}>
            <label className={style.informationLabel}>Profesor Reemplazo:</label>
            <span>{isOpenPreview?.replacementTeacherName || 'N/A'}</span>
          </div>
        </div>
        <div className={styleForm.form__container_row}>
          <div style={{ width: '100%' }}>
            <label className={style.informationLabel}>Atraso (min.):</label>
            <span>{isOpenPreview?.timeLate ?? 0}</span>
          </div>
          <div style={{ width: '100%' }}>
            <label className={style.informationLabel}>Retiro Anticipado (min.):</label>
            <span>{isOpenPreview?.timeEarly ?? 0}</span>
          </div>
        </div>
        <div className={styleForm.form__container_row}>
          <div style={{ width: '100%' }}>
            <label className={style.informationLabel}>Cambio sala:</label>
            <span>
              {isOpenPreview?.remplacementCampusId || isOpenPreview?.remplacementBuildingId || isOpenPreview?.remplacementRoomId
                ? `${isOpenPreview?.remplacementCampusId ?? ''} / ${isOpenPreview?.remplacementBuildingId ?? ''} / ${isOpenPreview?.remplacementRoomId ?? ''}`
                : 'N/A'}
            </span>
          </div>


          <div style={{ width: '100%' }}>
            <label className={style.informationLabel}>Fecha Última Actualización:</label>
            <span>{isOpenPreview?.lastUpdateDate ? hFormatDate(isOpenPreview?.lastUpdateDate, "dd-MM-yyyy") : "N/A"}</span>
          </div>
        </div>
        <div className={styleForm.form__container_row}>
          <div style={{ width: '100%' }}>
            <label className={style.informationLabel}>Observaciones:</label>
            <span>{isOpenPreview?.observations ?? 'N/A'}</span>
          </div>
        </div>
        <div className={styleForm.form__container_row} style={{ justifyContent: 'flex-start' }}>
          <div className={style.iconsTableView} >
            <div style={{ width: '100%' }} >
              <label className={style.informationLabel}>Estado:</label>
              <span className={
                isOpenPreview?.reasonId === 4 ? style.estadoSalidaTerreno :
                  isOpenPreview?.reasonId === 5 ? style.estadoDocenteAtrasado :
                    isOpenPreview?.reasonId === 6 ? style.estadoCambioModalidad :
                      isOpenPreview?.reasonId === 7 ? style.estadoCambioSala :
                        isOpenPreview?.reasonId === 8 ? style.estadoIniciada :
                          isOpenPreview?.reasonId === 9 ? style.estadoFinalizada :
                            isOpenPreview?.reasonId === 10 ? style.cancelado :
                              isOpenPreview?.reasonId === 11 ? style.cambioDocente :
                                isOpenPreview?.finished === 0 ? style.estadoIniciada :
                                  isOpenPreview?.finished === 1 ? style.estadoFinalizada :
                                    isOpenPreview?.finished === 2 ? style.estadoReabierta :
                                      isOpenPreview?.timeStatus === 1 ? style.noIniciado :
                                        isOpenPreview?.timeStatus === 2 ? style.enCurso :
                                          isOpenPreview?.timeStatus === 3 ? style.finalizado :
                                            ''
              }
              >
                {isOpenPreview?.reasonId === 4 ? "Salida terreno" :
                  isOpenPreview?.reasonId === 5 ? "Docente atrasado" :
                    isOpenPreview?.reasonId === 6 ? "Cambio a modalidad online" :
                      isOpenPreview?.reasonId === 7 ? "Cambio de sala" :
                        isOpenPreview?.reasonId === 8 ? "Iniciada" :
                          isOpenPreview?.reasonId === 9 ? "Finalizada" :
                            isOpenPreview?.reasonId === 10 ? "Cancelada" :
                              isOpenPreview?.reasonId === 11 ? "Cambio docente" :
                                isOpenPreview?.finished === 0 ? "Iniciada" :
                                  isOpenPreview?.finished === 1 ? "Finalizada" :
                                    isOpenPreview?.finished === 2 ? "Reabierta" :
                                      isOpenPreview?.timeStatus === 1 ? "No iniciada" :
                                        isOpenPreview?.timeStatus === 2 ? "En curso" :
                                          isOpenPreview?.timeStatus === 3 ? "Finalizada" :
                                            ''}
                <AppGetIcon
                  name={
                    isOpenPreview?.reasonId === 4 ? "circleCheck" :
                      isOpenPreview?.reasonId === 5 ? "circleCheck" :
                        isOpenPreview?.reasonId === 6 ? "circleCheck" :
                          isOpenPreview?.reasonId === 7 ? "circleCheck" :
                            isOpenPreview?.reasonId === 8 ? "circleCheck" :
                              isOpenPreview?.reasonId === 9 ? "circleCheck" :
                                isOpenPreview?.reasonId === 10 ? "circleCheck" :
                                  isOpenPreview?.reasonId === 11 ? "circleCheck" :
                                    isOpenPreview?.finished === 0 ? "circleCheck" :
                                      isOpenPreview?.finished === 1 ? "circleCheck" :
                                        isOpenPreview?.finished === 2 ? "circleCheck" :
                                          isOpenPreview?.timeStatus === 1 ? "clockV2" :
                                            isOpenPreview?.timeStatus === 2 ? "infoCircle" :
                                              isOpenPreview?.timeStatus === 3 ? "circleCheck" :
                                                ''
                  }
                  classIcon={styleDataTable.sizeIcon}
                  hoverColor={"#FFF"}
                  strokeColor="#FFF"
                />
              </span>

            </div>
          </div>
        </div>
      </Modal>


      <Modal
        title={'Editar Programación diaria'}
        isOpen={isOpen}
        onClose={handleCloseModal}
        size={{ width: '90%', maxWidth: '1000px', height: '' }}

        footerContent={
          <div className={styleButton.Container__buttoms_modal}>

            <button
              className={styleButton.Buttom__modal_cancel}
              onClick={handleCloseModal}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className={styleButton.Buttom__modal_save}
              form="uploadFile"
            >
              Guardar
            </button>

          </div>
        }
      >
        <div>
          <Formik
            key={initialData?.id ?? "new"}
            initialValues={{
              id: initialData?.id ?? null,
              reasonId: reason ?? null,
              creatorUserId: initialData?.creatorUserId ?? null,
              attend: initialData?.attend ?? null,
              finished: initialData?.finished ?? null,
              replacementTeacherId: initialData?.replacementTeacherId ?? null,
              replacementTeacherName: initialData?.replacementTeacherName ?? '',
              teacherNameString: initialData?.teacherNameString ?? '',
              timeLate: initialData?.timeLate ?? '',
              timeEarly: initialData?.timeEarly ?? '',
              minutesLate: initialData?.minutesLate ?? null,
              minutesEarly: initialData?.minutesEarly ?? '',
              editorUserId: initialData?.editorUserId ?? null,
              NRC: initialData?.NRC ?? null,
              roomId: initialData?.roomId ?? null,
              buildingId: initialData?.buildingId ?? null,
              campusId: initialData?.campusId ?? null,
              termCode: initialData?.termCode ?? null,
              dateSince: hFormatDate(initialData?.dateSince, "yyyy-MM-dd") ?? null,
              dateUntil: hFormatDate(initialData?.dateUntil, "yyyy-MM-dd") ?? null,
              startTime: initialData?.startTime ?? null,
              endTime: initialData?.endTime ?? null,
              remplacementCampusId: campus.value ?? null,
              remplacementBuildingId: buildingId.value ?? null,
              remplacementRoomId: roomId.value ?? null,
              observations: initialData?.observations ?? null,
              type: initialData?.type ?? null,
              updatedClassId: initialData?.updatedClassId ?? null,
              reasonName: initialData?.reasonName ?? '',

            }}
            validationSchema={reservationLimitSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, errors, values, setFieldValue, touched, setFieldError, setFieldTouched, validateForm }) => {
              return (
                <Form id="uploadFile" className="flex-row" style={{ width: '100%' }}>
                  <div className={styleForm.form__container_row}>
                    <Field name="reasonId">
                      {({
                        field,
                        form: { setFieldValue, validateForm, setFieldTouched, setFieldError, errors, touched, values },

                      }: FieldProps) => (
                        console.log("errors", errors),
                        <>

                          <InputSearch
                            key={Math.random()}
                            name="reasonId"
                            classNameInput={styleForm.input__input}
                            textLabel="Comentario pantalla"
                            serviceCall={serviceCallReasons}
                            onSelect={(selected) => {
                              setReason({ label: selected.label, value: selected.value });  // Actualiza `reason`
                              setCampus({ label: '', value: '' });
                              setBuildingId({ label: '', value: '' });
                              setRoomId({ label: '', value: '' });
                              setFieldValue("replacementTeacherId", '');  // Actualiza `values.reasonId`
                              setReplacementTeacher({ label: '', value: '' });

                              setFieldValue("reasonId", selected.value);  // Actualiza `values.reasonId`
                            }}
                            selected={reason}  // Pasa el estado `reasonId` seleccionado
                            errorMessage={touched.reasonId && errors.reasonId ? errors.reasonId : ""}
                          />

                        </>
                      )}
                    </Field>

                    <div className={styleForm.containerChecks}>
                      <label className={style.input__label}>Asistente</label>
                      <div className={styleForm.contentCheck}>
                        <div className={styleForm.check}>
                          {/* Checkbox para "Asiste" */}
                          <Field name="attend">
                            {({ field }: any) => (
                              <>
                                <input
                                  {...field}
                                  id="1"
                                  type="checkbox"
                                  checked={values.attend === 1 || (values.attend === null && values.finished === 0)}
                                  onChange={() => {
                                    setFieldValue("attend", 1);
                                  }}
                                />
                                <label htmlFor="1" className={style.title}>Asiste</label>
                              </>
                            )}
                          </Field>
                        </div>
                        <div className={styleForm.check}>
                          {/* Checkbox para "No asiste" */}
                          <Field name="attend">
                            {({ field }: any) => (
                              <>
                                <input
                                  {...field}
                                  id="0"
                                  type="checkbox"
                                  checked={values.attend === 0}
                                  onChange={() => {
                                    setFieldValue("attend", 0);
                                  }}
                                />
                                <label htmlFor="0" className={style.title}>No asiste</label>
                              </>
                            )}
                          </Field>
                        </div>
                      </div>

                      {/* Mensaje de validación según el valor de finished */}
                      <div className={styleForm.message}>
                        {values.finished === null ? (
                          <span className={styleForm.messageError}>Docente no ha iniciado clase.</span>
                        ) : (
                          <span className={styleForm.messageSuccess}>Docente inició clase.</span>
                        )}
                      </div>

                      {touched.attend && errors.attend && (
                        <div className={styleForm.messageError}>{errors.attend}</div>
                      )}
                    </div>


                  </div>
                  <div className={styleForm.form__container_row}>
                    {String(reason.value) === "11" ? (
                      <>

                        <AppInputSearch
                          key={Math.random()}
                          name="replacementTeacherId"
                          classNameInput={styleForm.input__input}
                          textLabel="Profesor reemplazo"
                          serviceCall={serviceUsersAll}
                          textAddString={'Agregar nuevo profesor'}
                          onSelect={async (selected) => {
                            setFieldValue("replacementTeacherId", selected.value);
                            setReason({ label: 'Cambio de docente', value: "11" });
                            setFieldValue("reasonId", '11');  // Actualiza `values.reasonId`
                            setReplacementTeacher(selected)
                            // Marcar el campo como tocado
                            setFieldTouched("replacementTeacherId", true, false);
                            // Forzar una validación completa del formulario
                          }}
                          selected={replacementTeacher}


                          errorMessage={typeof errors.replacementTeacherId === "string" ? errors.replacementTeacherId : undefined}



                          addString

                        />

                      </>
                    ) : null}

                    <DateInputFormik
                      name="timeLate"
                      placeholder="HH:mm"
                      TextLabel="Atraso (min.)"
                      dateFormat="HH:mm"
                      showTimeSelect
                      showTimeSelectOnly
                      classNameInput={styleForm.input__input}
                    />
                    <DateInputFormik
                      name="timeEarly"
                      placeholder="HH:mm"
                      TextLabel="Retiro anticipado (min.)"
                      dateFormat="HH:mm"
                      showTimeSelect
                      showTimeSelectOnly
                      classNameInput={styleForm.input__input}
                    />


                  </div>

                  {/* Inputs condicionados por reasonId */}
                  <div className={styleForm.form__container_row}>
                    {String(reason.value) === "7" ? (
                      <>
                        <Field name="remplacementCampusId">
                          {({
                            field,
                            form: { setFieldValue, validateForm, setFieldTouched, setFieldError, errors, touched, values },
                          }: FieldProps) => (
                            <>
                              <InputSearch
                                key={Math.random()}
                                name="remplacementCampusId"
                                classNameInput={styleForm.input__input}
                                textLabel="Nuevo campus"
                                serviceCall={serviceCallNewCampus}
                                onSelect={(selected) => {
                                  setCampus({ label: selected.label, value: selected.value });
                                  setBuildingId({ label: '', value: '' });
                                  setRoomId({ label: '', value: '' });
                                  setFieldValue("remplacementCampusId", selected.value); // Marcar el campo como tocado
                                  setFieldTouched("remplacementCampusId", true, false);
                                  // Forzar una validación completa del formulario
                                  validateForm().then(() => {
                                    // Limpia manualmente el error si el campo es válido
                                    if (selected?.value) {
                                      setFieldError("remplacementCampusId", ""); // Limpia el error manualmente
                                    }
                                  });
                                }}
                                selected={campus}
                                errorMessage={touched.remplacementCampusId && errors.remplacementCampusId ? errors.remplacementCampusId : ""}
                              />
                            </>
                          )}
                        </Field>
                        <Field name="remplacementBuildingId">
                          {({
                            field,
                            form: { setFieldValue, validateForm, setFieldTouched, setFieldError, errors, touched, values },
                          }: FieldProps) => (
                            <>
                              <InputSearch
                                key={Math.random()}
                                name="remplacementBuildingId"
                                classNameInput={styleForm.input__input}
                                textLabel="Nuevo edificio"
                                serviceCall={serviceCallBuilding}
                                onSelect={(selected) => {
                                  setFieldValue("remplacementBuildingId", selected.value);
                                  setBuildingId(selected);
                                  setRoomId({ label: '', value: '' });


                                  // Forzar una validación completa del formulario
                                  validateForm().then(() => {
                                    // Limpia manualmente el error si el campo es válido
                                    if (selected?.value) {
                                      setFieldError("remplacementBuildingId", ''); // Limpia el error manualmente
                                    }
                                  });

                                }}
                                selected={buildingId}
                                errorMessage={touched.remplacementBuildingId && errors.remplacementBuildingId ? errors.remplacementBuildingId : ''}
                              />
                            </>
                          )}
                        </Field>
                        <Field name="remplacementRoomId">
                          {({
                            field,
                            form: { setFieldValue, validateForm, setFieldTouched, setFieldError, errors, touched, values },
                          }: FieldProps) => (
                            <>
                              <InputSearch
                                key={Math.random()}
                                name="remplacementRoomId"
                                classNameInput={styleForm.input__input}
                                textLabel="Nuevo salón"
                                serviceCall={serviceCallRooms}
                                onSelect={(selected) => {
                                  setFieldValue("remplacementRoomId", selected.value);
                                  setRoomId(selected);
                                  // Forzar una validación completa del formulario
                                  validateForm().then(() => {
                                    // Limpia manualmente el error si el campo es válido
                                    if (selected?.value) {
                                      setFieldError("remplacementRoomId", ""); // Limpia el error manualmente
                                    }
                                  });
                                }}
                                selected={roomId}
                                errorMessage={touched.remplacementRoomId && errors.remplacementRoomId ? errors.remplacementRoomId : ""}
                              />
                            </>
                          )}
                        </Field>
                      </>
                    ) : null}
                  </div>

                  <div className={styleForm.form__container_row}>

                    <InputTextArea
                      TextLabel="Observaciones"
                      name="observations"
                      placeholder="Ingresa una observación"
                      classNameInput={styleForm.input__input}
                    />
                  </div>

                </Form>
              )
            }}
          </Formik>
        </div>

      </Modal>
    </div>
  );
}
